import {create} from 'zustand'
import {persist} from "zustand/middleware";
// import {createJSONStorage} from "zustand/middleware";

const memberClass = (enable = false) => {
    return {
        enable: enable,
        memberRefNo: null,
        enrolmentIssueDate: null,
        enrolmentStartDate: null,
        unifiedNo: null,
        emiratesIdNo: null,
        visaFileNo: null,
        birthCertNo: null,
        passportNo: null,
        gender: null,
        nationalityCode: null,
        dateOfBirth: null,
        fullNameEn: null,
        firstNameEn: null,
        middleNameEn: null,
        lastNameEn: null,
        fullNameAr: null,
        firstNameAr: null,
        middleNameAr: null,
        lastNameAr: null,
        maritalStatus: null,
        relationWithSponsor: null,
        memberIdType: null,
        memberType: null,
        sponsorIdNo: null,
        sponsorIdType: null,
        membershipCardNo: null,
        salaryBand: null,
        occupationDesc: null,
        emiratesOfVisaCode: null,
        emiratesOfLivingCode: null,
        className: null,
    };
}

const policyCreateClass = (enable = false) => {
    return {
        policyNumber: null,
        policyIssueDate: null,
        policyStartDate: null,
        policyExpiryDate: null,
        policyType: null,
        policyOwnerType: null,
        policyOwnerName: null,
        policyOwnerId: null,
        planName: null,
        members: [
            memberClass(true),
            memberClass(),
            memberClass(),
            memberClass(),
        ],
    }
}

const usePolicyCreateStore = create((set, get) => ({
    ...policyCreateClass(),

    updateMember: (key, index, value) => set((state) => {
        let members = state.members;
        members[index][key] = value
        return {...state, members};
    }),

    resetPolicyCreateForm: () => set(state => ({...state, ...policyCreateClass()})),

    // setMemberFromGraphQLRequest: (values) => set({
    //     id: null,
    //     memberRefNo: null,
    //     ...values
    // }),

    setPolicyNumber: (value) => set({policyNumber: value}),
    setPolicyIssueDate: (value) => set({policyIssueDate: value}),
    setPolicyStartDate: (value) => set({policyStartDate: value}),
    setPolicyExpiryDate: (value) => set({policyExpiryDate: value}),
    setPolicyType: (value) => set({policyType: value}),
    setPolicyOwnerType: (value) => set({policyOwnerType: value}),
    setPolicyOwnerName: (value) => set({policyOwnerName: value}),
    setPolicyOwnerId: (value) => set({policyOwnerId: value}),
    setPlanName: (value) => set({planName: value}),

}))

export default usePolicyCreateStore;