import React from 'react';
import {Link} from "react-router-dom";

function IconButtonLink({children, href}) {
	return (
		<Link to={href || '#'}
		   className="flex items-center justify-center px-2 py-2 text-lg leading-tight bg-gray-200 hover:bg-primary-100 rounded-full hover:outline outline-primary-300 outline-1">
			{children}
		</Link>
	);
}

export default IconButtonLink;