import React from 'react';
import {clsx} from "clsx";

function ButtonLong({type, children, onClick, secondary, className, disabled}) {
	return (
		<button
			onClick={e => {
				e.preventDefault();
				onClick(e);
			}}
			type={type || 'submit'}
			disabled={disabled}
			className={clsx(
				className,
				disabled ? 'disabled:opacity-60' : '',
				secondary
					? 'from-gray-500 to-gray-600 hover:from-gray-400 hover:to-gray-500'
					: 'from-secondary-500 to-primary-600 hover:from-secondary-400 hover:to-primary-500',
				"flex w-full justify-center rounded-md bg-gradient-to-br px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500")}
		>
			{children}
		</button>
	);
}

export default ButtonLong;