import React from 'react';
import StatusBadge from "./StatusBadge";

export const StatusColor = {
    default: 'bg-gray-100 text-gray-700 border-gray-200',
    pending: 'bg-orange-100 text-orange-700/70 border-orange-200',
    completed: 'bg-green-100 text-green-700 border-green-200',
    paid: 'bg-green-100 text-green-700 border-green-200',
    failed: 'bg-rose-100 text-rose-700 border-rose-200',
    expired: 'bg-rose-100 text-rose-700 border-rose-200',
    refunded: 'bg-orange-200 text-orange-700/70 border-orange-200',
    canceled: 'bg-rose-200 text-rose-700/70 border-rose-200',
    on_hold: 'bg-rose-700 text-rose-100 border-rose-100',
    'in progress': 'bg-pink-100 text-pink-700 border-pink-200',

    active: 'bg-green-100 text-green-700 border-green-200',
    inactive: 'bg-rose-100 text-rose-700 border-rose-200',

    accepted: 'bg-green-100 text-green-700 border-green-200',
    rejected: 'bg-rose-100 text-rose-700 border-rose-200',

    Yes: 'bg-green-100 text-green-700 border-green-200',
    No: 'bg-rose-100 text-rose-700 border-rose-200',

    not_found: 'bg-rose-100 text-rose-700 border-rose-200',
    unknown_error: 'bg-rose-100 text-rose-700 border-rose-200',
}

function ArrayStatusBadge({statusList, boolean, w, h}) {
    let list = [];
    for (const index in statusList) {
        const status = statusList[index];
        list.push(<StatusBadge key={index} h='h-3' w='w-3' status={status} boolean={true}/>);
    }

    return <div className="grid grid-cols-8 gap-0.5">
        {list}
    </div>;
}

export default ArrayStatusBadge;