import {create} from 'zustand'
import {persist} from "zustand/middleware";
// import {createJSONStorage} from "zustand/middleware";

const memberClass = () => {
    return {
        id: null,
        memberRefNo: null,
        enrolmentIssueDate: null,
        enrolmentStartDate: null,
        unifiedNo: null,
        emiratesIdNo: null,
        visaFileNo: null,
        birthCertNo: null,
        passportNo: null,
        gender: null,
        nationalityCode: null,
        dateOfBirth: null,
        fullNameEn: null,
        firstNameEn: null,
        middleNameEn: null,
        lastNameEn: null,
        fullNameAr: null,
        firstNameAr: null,
        middleNameAr: null,
        lastNameAr: null,
        maritalStatus: null,
        relationWithSponsor: null,
        memberIdType: null,
        memberType: null,
        sponsorIdNo: null,
        sponsorIdType: null,
        membershipCardNo: null,
        className: null,
        salaryBand: null,
        occupationDesc: null,
        emiratesOfVisaCode: null,
        emiratesOfLivingCode: null,
        memberCreationRefNo: null,
        cancellationDate: null,
        integrationStatus: null,
        status: null,
        createdAt: null,
        updatedAt: null,
    };
}

const useMemberEditStore = create(persist((set) => ({
    ...memberClass(),
    setMemberFromGraphQLRequest: (values) => set({
        ...memberClass(),
        ...values
    }),
    updateMember: (key, value) => set((state) => {
        let members = state;
        members[key] = value
        return {...state, members};
    }),
    // setMemberRefNo: (value) => set({memberRefNo: value}),
    // setEnrolmentIssueDate: (value) => set({enrolmentIssueDate: value}),
    // setEnrolmentStartDate: (value) => set({enrolmentStartDate: value}),
    // setUnifiedNo: (value) => set({unifiedNo: value}),
    // setEmiratesIdNo: (value) => set({emiratesIdNo: value}),
    // setVisaFileNo: (value) => set({visaFileNo: value}),
    // setBirthCertNo: (value) => set({birthCertNo: value}),
    // setPassportNo: (value) => set({passportNo: value}),
    // setGender: (value) => set({gender: value}),
    // setNationalityCode: (value) => set({nationalityCode: value}),
    // setDateOfBirth: (value) => set({dateOfBirth: value}),
    // setFullNameEn: (value) => set({fullNameEn: value}),
    // setFirstNameEn: (value) => set({firstNameEn: value}),
    // setMiddleNameEn: (value) => set({middleNameEn: value}),
    // setLastNameEn: (value) => set({lastNameEn: value}),
    // setFullNameAr: (value) => set({fullNameAr: value}),
    // setFirstNameAr: (value) => set({firstNameAr: value}),
    // setMiddleNameAr: (value) => set({middleNameAr: value}),
    // setLastNameAr: (value) => set({lastNameAr: value}),
    // setMaritalStatus: (value) => set({maritalStatus: value}),
    // setRelationWithSponsor: (value) => set({relationWithSponsor: value}),
    // setMemberIdType: (value) => set({memberIdType: value}),
    // setMemberType: (value) => set({memberType: value}),
    // setSponsorIdNo: (value) => set({sponsorIdNo: value}),
    // setSponsorIdType: (value) => set({sponsorIdType: value}),
    // setMembershipCardNo: (value) => set({membershipCardNo: value}),
    // setClassName: (value) => set({className: value}),
    // setSalaryBand: (value) => set({salaryBand: value}),
    // setOccupationDesc: (value) => set({occupationDesc: value}),
    // setEmiratesOfVisaCode: (value) => set({emiratesOfVisaCode: value}),
    // setEmiratesOfLivingCode: (value) => set({emiratesOfLivingCode: value}),
    // setMemberCreationRefNo: (value) => set({memberCreationRefNo: value}),
    // setCancellationDate: (value) => set({cancellationDate: value}),

}), {
    name: 'x-policy-edit', // name of item in the storage (must be unique)
    //storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    // partialize: (state) => ({
    // 	integrationType: state.integrationType,
    // }),
}))

export default useMemberEditStore;