import React, {useMemo, useState} from 'react';
import {useQuery, useQueryClient} from "react-query";
import {useNavigate} from "react-router-dom";
import {apollo} from "../../../../utils/apollo";
import {GET_FILE_IMPORT_LIST} from "../../../../utils/graphql";
import PaginationFull from "../../../../components/PaginationFull";


function PolicyImportView({filterStatus}) {
    const queryClient = useQueryClient()
    const navigate = useNavigate();
    const [page, setPage] = useState(1);

    const fetchFileImportProcessing = useQuery({
        queryKey: ['policy_import_processing_view_list', {page, filterStatus}], queryFn: async () => {
            const {data} = await apollo.query({
                query: GET_FILE_IMPORT_LIST, variables: {
                    page,
                    first: 15,
                    filter: {
                        status: filterStatus || null,
                    }
                },
            });
            return data?.getFileImportList;
        },
        keepPreviousData: false
    });

    const fileImports = useMemo(() => fetchFileImportProcessing?.isSuccess ? fetchFileImportProcessing?.data : null, [fetchFileImportProcessing]);

    return (<div className="mt-6">
        {filterStatus === 'completed' && <div
            className="flex flex-col md:flex-row gap-4 my-4 border border-sky-200 bg-sky-50 p-4 rounded text-sm text-sky-800">
            In this tab, you can find the files that was uploaded successfully to SlashData, along with the
            successfully added members list, members that has been added because of an errors, and the members that
            has been rejected by ICP. Members with errors should be uploaded again.
        </div>}
        {filterStatus === 'failed' && <div
            className="flex flex-col md:flex-row gap-4 my-4 border border-sky-200 bg-sky-50 p-4 rounded text-sm text-sky-800">
            In this tab, you can find the files that failed to be uploaded to SlashData due to validations errors in
            the excel file all the members in these files has not been added to SlashData system, therefore,
            validation errors should be fixed and try to upload the file again.
        </div>}
        <div className="mt-4 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle">
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                        <tr>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                fileImportUuid
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                integration
                            </th>
                            {/*<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">*/}
                            {/*    user*/}
                            {/*</th>*/}
                            {/*<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">*/}
                            {/*    filename*/}
                            {/*</th>*/}
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                totalRecords
                            </th>
                            {/*<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">*/}
                            {/*    importedRecords*/}
                            {/*</th>*/}
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                errors
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                status
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                startedAt
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                completedAt
                            </th>
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                        {fileImports?.data?.map((fileImport) => (
                            <tr key={fileImport.fileImportUuid}>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {fileImport.fileImportUuid}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {fileImport.integration?.name}
                                </td>
                                {/*<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">*/}
                                {/*    {fileImport.user?.name}*/}
                                {/*</td>*/}
                                {/*<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">*/}
                                {/*    {fileImport.filename}*/}
                                {/*</td>*/}
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {fileImport.totalRecords}
                                </td>
                                {/*<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">*/}
                                {/*    {fileImport.importedRecords}*/}
                                {/*</td>*/}
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {fileImport.errors?.map((err, i) => <div key={i}>{err}</div>)}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {fileImport.status}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {fileImport.startedAt}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {fileImport.completedAt}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    {fileImports?.data && !fileImports?.data?.length &&
                        <div className="flex items-center justify-center h-40 text-gray-500 font-light text-xl">
                            No file imports found
                        </div>}
                </div>
            </div>
        </div>
        <PaginationFull paginator={fileImports?.paginatorInfo} get={page} set={setPage}/>
    </div>);
}

export default PolicyImportView;
