import React from 'react';
import {ChevronDownIcon, ChevronUpIcon} from '@heroicons/react/20/solid'
import {clsx} from "clsx";

function SortIcon({sort, field}) {
	const iconClassName = sort.field === field ? 'h-5 w-5' : 'invisible h-5 w-5 flex-none rounded text-gray-400 group-hover:visible group-focus:visible';
	
	
	return <span className={clsx(sort.field === field ? 'ml-2 flex-none rounded bg-gray-100 text-gray-900 group-hover:bg-gray-200' : 'invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible')}>
		{sort.isAscending ? <ChevronDownIcon className={iconClassName} aria-hidden="true"/> : <ChevronUpIcon className={iconClassName} aria-hidden="true"/>}
		
	</span>
}

export default SortIcon;