import React from 'react';

function InformationColumn({title, value, defaultValue}) {
	return <div>
		<dt className="text-sm font-medium leading-6 text-gray-900">{title}</dt>
		<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
			{value || defaultValue || <span className="text-gray-400">N/A</span>}
		</dd>
	</div>
}

export default InformationColumn;