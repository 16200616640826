import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

const isProduction = process.env.NODE_ENV === 'production';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: !isProduction,
    fallbackLng: 'en',
    saveMissing: !isProduction,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true
    },
    // Instead of preloading all language resources, let i18next http backend handle asynchronous loading
    resources: undefined,
    load: 'languageOnly', // load only current language
    backend: { // setup backend options
      loadPath: '/locales/{{lng}}/common.json',
      crossDomain: false
    },
    supportedLngs: ['en', 'ar', 'fr'],
    whitelist: ['en', 'ar', 'fr'],
    returnEmptyString: true
  });

export default i18n;