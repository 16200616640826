import React from 'react';
import {clsx} from "clsx";
import {CheckIcon, XMarkIcon, ExclamationTriangleIcon} from "@heroicons/react/24/outline";
import {cn} from "../utils/style";

export const StatusColor = {
    default: 'bg-gray-100 text-gray-700 border-gray-200',
    pending: 'bg-orange-100 text-orange-700/70 border-orange-200',
    completed: 'bg-green-100 text-green-700 border-green-200',
    paid: 'bg-green-100 text-green-700 border-green-200',
    failed: 'bg-rose-100 text-rose-700 border-rose-200',
    expired: 'bg-rose-100 text-rose-700 border-rose-200',
    refunded: 'bg-orange-200 text-orange-700/70 border-orange-200',
    canceled: 'bg-rose-200 text-rose-700/70 border-rose-200',
    on_hold: 'bg-rose-700 text-rose-100 border-rose-100',
    'in progress': 'bg-pink-100 text-pink-700 border-pink-200',

    active: 'bg-green-100 text-green-700 border-green-200',
    inactive: 'bg-rose-100 text-rose-700 border-rose-200',

    accepted: 'bg-green-100 text-green-700 border-green-200',
    rejected: 'bg-rose-100 text-rose-700 border-rose-200',

    Yes: 'bg-green-100 text-green-700 border-green-200',
    No: 'bg-rose-100 text-rose-700 border-rose-200',

    not_found: 'bg-rose-100 text-rose-700 border-rose-200',
    unknown_error: 'bg-rose-100 text-rose-700 border-rose-200',
}

function StatusBadge({status, boolean, w, h}) {
    const width = !!w ? w : 'w-4';
    const height = !!h ? h : 'h-4';

    if (boolean) {
        return (
            <div className={cn(
                "border border-1 inline-flex items-center p-0.5 rounded-full font-semibold uppercase border",
                {"border-blue-300 text-blue-400": status === true},
                {"border-red-300 text-red-400": status === false},
                {"border-orange-300 text-orange-400": status === null},
            )}>
                {status === true &&
                    <CheckIcon className={clsx(width, height, "flex-shrink-0 self-center")} aria-hidden="true"/>}
                {status === false &&
                    <XMarkIcon className={clsx(width, height, "flex-shrink-0 self-center")} aria-hidden="true"/>}
                {status === null &&
                    <div
                        className={clsx(width, height, "flex-shrink-0 self-center items-center justify-center flex")}>!</div>}
            </div>
        );
    }

    const statusClass = StatusColor[status?.toLocaleLowerCase() || status] || StatusColor.default;
    return (
        <span
            className={clsx(statusClass, "inline-flex items-center rounded-md px-2 py-1 text-[11px] font-semibold uppercase border")}>{status}</span>
    );
}

export default StatusBadge;