import React, {useEffect} from 'react';
import useAuthStore from "../../store/auth";
import {useNavigate} from "react-router-dom";
import {useQueryClient} from "react-query";

function Logout() {
	const {token, clearToken} = useAuthStore();
	const navigate = useNavigate();
	const queryClient = useQueryClient()
	
	useEffect(() => {
		queryClient.clear();
	}, []);
	
	useEffect(() => {
		if (!token) {
			navigate("/login");
			return;
		}
		setTimeout(clearToken(), 1000)
	});
	
	return (
		<div>Sign out</div>
	);
}

export default Logout;