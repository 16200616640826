import React, {useMemo} from 'react';
import DashboardLayout from "../../../layouts/DashboardLayout";
import SelectInput from "../../../components/SelectInput";
import {useQuery, useQueryClient} from "react-query";
import {useNavigate} from "react-router-dom";
import usePolicyStore from "../../../store/policy";
import {apollo} from "../../../utils/apollo";
import {GET_INTEGRATION_LIST} from "../../../utils/graphql";
import PageHeader from "../../../components/PageHeader";
import SlashdataReportForm from "./forms/slashdata-report-form";
import SlashdataReportView from "./views/slashdata-report-view";

function ReportList() {
    const queryClient = useQueryClient()
    const navigate = useNavigate();
    const {integrationUuid, setIntegrationUuid} = usePolicyStore();

    const fetchIntegrationList = useQuery({
        queryKey: 'integration_list', queryFn: async () => {
            const {data} = await apollo.query({
                query: GET_INTEGRATION_LIST, variables: {},
            });
            return data?.getIntegrationList;
        }, keepPreviousData: false
    })

    const integrationList = useMemo(() => fetchIntegrationList?.isSuccess ? fetchIntegrationList?.data?.data : null, [fetchIntegrationList]);
    const integration = useMemo(() => {
        const l = integrationList?.filter(i => i.integrationUuid === integrationUuid);
        return l && l.length > 0 ? l[0] : null;
    }, [integrationUuid, integrationList]);

    return (<DashboardLayout>
            <PageHeader title="Reports" description="Generate members reports">
                <div className="shrink-0 w-full sm:w-[250px]">
                    <SelectInput name="integration_list" label="Integration" id="integration_list" get={integrationUuid}
                                 set={setIntegrationUuid}>
                        <option value=""></option>
                        {integrationList && integrationList?.map((integration) => (
                            <option key={integration?.integrationUuid}
                                    value={integration?.integrationUuid}>{`${integration?.name} | ${integration?.integrationUuid}`}</option>))}
                    </SelectInput>
                </div>
            </PageHeader>

            <div className="mt-6">
                {integration?.integrationType === 'slashdata' && <>
                    <SlashdataReportForm integration={integration}/>
                </>}
                {(!integrationUuid) &&
                    <div className="flex items-center justify-center h-40 text-gray-500 font-light text-xl">
                        Select integration to continue.
                    </div>}

            </div>
        </DashboardLayout>
    );
}

function FormAndView() {
    //
//
}


export default ReportList;