import React from 'react';
import {clsx} from "clsx";
import {cn} from "../utils/style";

function Tabs({tabs, get, set, rightSide, hideOnMobile = true}) {
    if (!tabs) return null;
    return (
        <div className="my-2">
            <div className={cn({'hidden sm:block': hideOnMobile})}>
                <div className="border-b border-gray-200">
                    <nav className={cn(`-mb-px flex flex-wrap gap-x-5`, {'justify-end': !!rightSide})} aria-label="Tabs">
                        {tabs.map((tab) => (
                            <span
                                key={tab?.column}
                                onClick={() => set(tab?.column)}
                                className={clsx(
                                    tab?.column === get
                                        ? 'border-primary-500 text-primary-600'
                                        : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                                    'cursor-pointer select-none flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'
                                )}
                                aria-current={tab?.column === get ? 'page' : undefined}
                            >
                {tab?.name}
                                {tab?.count > 0 ? (
                                    <span
                                        className={clsx(
                                            tab?.column === get ? 'bg-primary-100 text-primary-600' : 'bg-gray-100 text-gray-900',
                                            'ltr:ml-2 rtl:mr-2 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block'
                                        )}
                                    >
                    {tab?.count}
                  </span>
                                ) : null}
              </span>
                        ))}
                    </nav>
                </div>
            </div>
        </div>

    );
}

export default Tabs;
