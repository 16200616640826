import React from 'react';
import DashboardLayout from "../../layouts/DashboardLayout";
import {PhotoIcon, UserCircleIcon} from '@heroicons/react/24/solid'
import Button from "../../components/Button";

function Profile() {
	return (
		<DashboardLayout>
			<form>
				<div className="space-y-12 sm:space-y-16">
					<div className="hidden">
						<h2 className="text-base font-semibold leading-7 text-gray-900">Personal Information</h2>
						<p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600">
							Use a permanent address where you can receive mail.
						</p>
						
						<div className="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
							<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
								<label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
									Full name
								</label>
								<div className="mt-2 sm:col-span-2 sm:mt-0">
									<input
										type="text"
										name="name"
										id="name"
										autoComplete="name"
										className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:max-w-xs sm:text-sm sm:leading-6"
									/>
								</div>
							</div>
							
							<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
								<label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
									Email address
								</label>
								<div className="mt-2 sm:col-span-2 sm:mt-0">
									<input
										id="email"
										name="email"
										type="email"
										autoComplete="email"
										className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:max-w-md sm:text-sm sm:leading-6"
									/>
								</div>
							</div>
							
							<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
								<label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
									Country
								</label>
								<div className="mt-2 sm:col-span-2 sm:mt-0">
									<select
										id="country"
										name="country"
										autoComplete="country-name"
										className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:max-w-xs sm:text-sm sm:leading-6"
									>
										<option>United States</option>
										<option>Canada</option>
										<option>Mexico</option>
									</select>
								</div>
							</div>
							
							<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
								<label htmlFor="street-address" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
									Street address
								</label>
								<div className="mt-2 sm:col-span-2 sm:mt-0">
									<input
										type="text"
										name="street-address"
										id="street-address"
										autoComplete="street-address"
										className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:max-w-xl sm:text-sm sm:leading-6"
									/>
								</div>
							</div>
						</div>
					</div>
					
					<div>
						<h2 className="text-base font-semibold leading-7 text-gray-900">Notifications</h2>
						<p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600">
							We'll always let you know about important changes, but you pick what else you want to hear about.
						</p>
						
						<div className="mt-10 space-y-10 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
							<fieldset>
								<legend className="sr-only">By Email</legend>
								<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:py-6">
									<div className="text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">
										By Email
									</div>
									<div className="mt-4 sm:col-span-2 sm:mt-0">
										<div className="max-w-lg space-y-6">
											<div className="relative flex gap-x-3">
												<div className="flex h-6 items-center">
													<input
														id="integration_fails"
														name="integration_fails"
														type="checkbox"
														className="h-4 w-4 rounded border-gray-300 text-primary-500 focus:ring-primary-500"
													/>
												</div>
												<div className="text-sm leading-6">
													<label htmlFor="integration_fails" className="font-medium text-gray-900">
														Integration failure
													</label>
													<p className="mt-1 text-gray-600">Receive notifications when integration push fails.</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</fieldset>
						</div>
					</div>
				</div>
				
				{/*<div className="mt-6 flex items-center justify-end gap-x-4">*/}
				{/*	<Button type="button" secondary={true} className="px-6 shrink-0">*/}
				{/*		Cancel*/}
				{/*	</Button>*/}
				{/*	<Button type="submit" className="px-20 w-full sm:w-auto">*/}
				{/*		Save*/}
				{/*	</Button>*/}
				{/*</div>*/}
			</form>
		</DashboardLayout>
	);
}

export default Profile;
