import React from 'react';
// import Language from "../components/Language";

function AuthLayout({title, children}) {
    return (
        <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img
                    className="mx-auto h-10 w-auto"
                    src="/logo-z.svg"
                    alt="Linkzdata"
                />
                <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    {title}
                </h2>
            </div>
            {children}
            {/*<div className="flex justify-center">*/}
            {/*    <Language className="hidden sm:block mt-4"/>*/}
            {/*</div>*/}
        </div>
    );
}

export default AuthLayout;