import React from 'react';

function Logo() {
	return (
		<img
			className="h-10 w-auto"
			src="/logo-z.svg"
			alt="Linkzdata"
		/>
	);
}

export default Logo;