import {cn} from "../utils/style";

// const tabs = [
//     {name: 'Overview', column: 'overview', current: true},
//     {name: 'Activity', column: 'activity', current: false},
//     {name: 'Settings', column: 'settings', current: false},
//     {name: 'Collaborators', column: 'collaborators', current: false},
//     {name: 'Notifications', column: 'notifications', current: false},
// ]

function SimpleTabs({tabs, get, set}) {
    // if (!tabs) return null;
    return (
        <div>
            <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">
                    Select a tab
                </label>
                <select
                    id="tabs"
                    name="tabs"
                    onChange={v=>set(v.target.value)}
                    // defaultValue={tabs.find((tab) => tab.current).column}
                    className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                >
                    {tabs.map((tab) => (
                        <option key={tab.column} value={tab.column}>{tab.name}</option>
                    ))}
                </select>
            </div>
            <div className="hidden sm:block">
                <nav aria-label="Tabs" className="flex space-x-4">
                    {tabs.map((tab) => (
                        <span
                            key={tab.column}
                            onClick={() => set(tab?.column)}
                            aria-current={tab.current ? 'page' : undefined}
                            className={cn(
                                tab.current ? 'bg-gray-100 text-gray-700' : 'text-gray-500 hover:text-gray-700',
                                'rounded-md px-3 py-2 text-sm font-medium cursor-pointer',
                            )}
                        >
                            {tab.name}
                        </span>
                    ))}
                </nav>
            </div>
        </div>
    )
}

export default SimpleTabs;