import React, {StrictMode, useEffect} from 'react';
import routes from "./routes";
import {createBrowserRouter, RouterProvider,} from "react-router-dom";
// import {http} from './utils/http';
import useAuthStore from "./store/auth"; // import your Axios context and instance
import {useTranslation} from 'react-i18next';
import {QueryClient, QueryClientProvider,} from 'react-query'
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const router = createBrowserRouter(routes);
const queryClient = new QueryClient()

function App() {
    const {token, user, setUserAuth, clearToken} = useAuthStore();
    const {i18n} = useTranslation();

    // async function getAccount() {
    //     try {
    //         const xAuth = localStorage.getItem('x-auth');
    //         if (!xAuth) return;
    //         if (xAuth) {
    //             const parsedXAuth = JSON.parse(xAuth);
    //             const token = parsedXAuth?.state?.token;
    //             if (!token) return;
    //         }
    //         const response = await http.get("user");
    //         const {user, roles, merchant} = await response?.data;
    //         if (user) {
    //             setUserAuth({user, roles, merchant});
    //         } else {
    //             clearToken();
    //         }
    //     } catch (e) {
    //         console.error(e)
    //         clearToken();
    //     }
    // }

    // useEffect(() => {
    //     if (token && !user) getAccount()
    //     if (!token && user) clearToken()
    // }, [token, user]);
    //
    // useEffect(() => {
    //     document.body.dir = i18n.language === 'ar' ? 'rtl' : 'ltr';
    // }, [i18n.language]);
    //
    // useEffect(() => {
    //     getAccount().then(r => {
    //         //
    //     });
    // }, []);

    return (// <AxiosContext.Provider value={http}>
        <StrictMode>
            <QueryClientProvider client={queryClient}>
                <RouterProvider router={router}/>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={i18n.language === 'ar'}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"/>
                {/*<ReactQueryDevtools initialIsOpen={false}/>*/}
                {/*{process.env.NODE_ENV === "development" && <ReactQueryDevtools />}*/}
            </QueryClientProvider>
        </StrictMode>
        // </AxiosContext.Provider>
    );
}

export default App;
