import React from 'react';
import DashboardLayout from "../../../layouts/DashboardLayout";
import PageHeader from "../../../components/PageHeader";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useQuery} from "react-query";
import SlashdataMemberEditForm from "./forms/slashdata-member-edit-form";

const MemberEdit = () => {
    const {t} = useTranslation();
    const {integration_type} = useParams();

    return (
        <DashboardLayout>
            <PageHeader title="Member Edit" description=""/>
            <div className="mt-6">
                {integration_type === 'slashdata' && <SlashdataMemberEditForm/>}
            </div>
        </DashboardLayout>
    );
};

export default MemberEdit;
