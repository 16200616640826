import {create} from 'zustand'
import {persist} from "zustand/middleware";
// import {createJSONStorage} from "zustand/middleware";

const usePolicyStore = create(persist((set) => ({
    integrationUuid: '',
    setIntegrationUuid: (value) => set({integrationUuid: value}),
}), {
    name: 'x-policy', // name of item in the storage (must be unique)
    //storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    // partialize: (state) => ({
    // 	integrationType: state.integrationType,
    // }),
}))

export default usePolicyStore;