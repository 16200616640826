import React from 'react';
import {useTranslation} from "react-i18next";
import StatusBadge from "../../../../components/StatusBadge";
import {EyeIcon} from "@heroicons/react/24/solid";
import SortIcon from "../../../../components/SortIcon";
import {Link} from "react-router-dom";
import ArrayStatusBadge from "../../../../components/ArrayStatusBadge";
import {Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/react'
import {EllipsisVerticalIcon} from '@heroicons/react/20/solid'
import {useMutation, useQueryClient} from "react-query";
import {apollo} from "../../../../utils/apollo";
import {RECHECK_MEMBER_STATUS} from "../../../../utils/graphql";
import {toast} from "react-toastify";

function CompactPolicyList({handleChangeSort, records, sort}) {
    const {t} = useTranslation();
    const tableHeader = [
        {column: 'policy_details', title: t('policy.table.policy_details'), sortable: false},
        {column: 'integration_details', title: t('policy.table.integration_details'), sortable: false},
        {column: 'policy_status', title: t('policy.table.policy_status'), sortable: false},
        {column: 'integration_status', title: t('policy.table.integration_status'), sortable: false},
        // {column: 'amount_details', title: t('policy.table.amount_details'), sortable: false},
        {column: 'view', title: t('policy.table.view'), sortable: false, hidden: true},]
    return <div className="flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                    <tr>
                        {tableHeader?.map(item => (<th key={item.column} scope="col"
                                                       className="px-3 py-3.5 ltr:text-left rtl:text-right text-sm font-semibold text-gray-900">
                            {!item.hidden && <>
                                {item.sortable ? <button onClick={() => handleChangeSort(item.column)} type="button"
                                                         className="group inline-flex">
                                    {item.title}
                                    <SortIcon sort={sort} field={item.column}/>
                                </button> : <span>{item.title}</span>}
                            </>}
                        </th>))}
                    </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                    {records?.data?.map((record) => (<tr key={record?.policyUuid} className="hover:bg-gray-50">
                        <td className="whitespace-nowrap align-top py-4 pl-4 pr-3 text-xs text-gray-500 sm:pl-0">
                            <Row title={`ID / Ref`} value={record?.policyUuid}/>
                            <Row title={`Policy Number`} value={record?.policyNumber || ''}/>
                            <Row title={`Created From`} value={record?.fromApi ? 'API' : 'Web Dashboard'}/>
                        </td>
                        <td className="whitespace-nowrap align-top px-3 py-4 text-xs text-gray-500">
                            <Row title={`Name`} value={record?.integration?.name}
                                 valueClassName="max-w-[150px] truncate"/>
                            <Row title={`Ref`} value={record?.integration?.integrationUuid}
                                 valueClassName="max-w-[150px] truncate"/>
                            <Row title={`Type`} value={record?.integration?.integrationType}/>
                        </td>
                        <td className="whitespace-nowrap align-top px-3 py-4 text-xs text-gray-500">
                            <div className="mb-2">
                                <StatusBadge status={record?.status}/>
                            </div>
                        </td>
                        <td className="whitespace-nowrap align-top px-3 py-4 text-xs text-gray-500">
                            <Row title={`Policy`}
                                 value={<StatusBadge h='h-3' w='w-3' status={record?.policyStatus} boolean={true}/>}/>
                            <Row title={`Members`}
                                 value={<ArrayStatusBadge h='h-3' w='w-3' statusList={record?.policyMembersStatus}
                                                          boolean={true}/>}/>
                        </td>
                        {/*<td className="whitespace-nowrap align-top px-3 py-4 text-xs text-gray-500">*/}
                        {/*	<Row title={`Payable Amount`} value={`${formatNumberFloat(record?.amount)} ${record?.currency?.symbol || ''}`}/>*/}
                        {/*	<Row title={`VAT Amount`} value={'1.02 AED'}/>*/}
                        {/*	<Row title={`Receivable Amount`} value={'1,770.56 AED'}/>*/}
                        {/*</td>*/}
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm sm:pr-0 flex gap-2 items-center">
                            <Actions record={record}/>
                        </td>
                    </tr>))}
                    </tbody>
                </table>
            </div>
        </div>
    </div>;
}


function Row({title, value, valueClassName}) {
    return <div className="flex items-start gap-1">
        <div className="font-medium">{title}{':'}</div>
        {value ? <div className={valueClassName}>{value}</div> : <div className="opacity-60">N/A</div>}
    </div>
}

function Actions({record}) {
    const queryClient = useQueryClient()

    const reCheckMemberStatusMutation = useMutation({
        mutationFn: async () => {
            const {data} = await apollo.mutate({
                mutation: RECHECK_MEMBER_STATUS,
                variables: {
                    policyUuid: record?.policyUuid
                },
            });
            return data?.reCheckMemberStatus;
        },
        onError: ((error) => {
            toast.error("Recheck operation failed")
            console.error(error)
        }),
        onSuccess: async (data) => {
            if (data) {
                toast.success("Recheck operation started")
            } else {
                toast.error("Recheck operation failed")
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries(['policy_list']);
        }
    })

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <MenuButton
                    className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                    <span className="sr-only">Open options</span>
                    <EllipsisVerticalIcon aria-hidden="true" className="h-6 w-6"/>
                </MenuButton>
            </div>

            <MenuItems
                transition
                className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
                <div className="py-1">
                    <MenuItem>
                        <Link
                            className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                            to={`/policy/view/${record?.policyUuid}`}>View</Link>
                    </MenuItem>
                    <MenuItem>
                        <span
                            className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                            onClick={() => reCheckMemberStatusMutation.mutate()}>Re-Check</span>
                    </MenuItem>
                </div>
            </MenuItems>
        </Menu>
    )
}


export default CompactPolicyList;