import React from 'react';
import {Link, useLocation} from "react-router-dom";

function Banner() {
	const location = useLocation();
	if (location.pathname === "/registration") return null;
	return (
		<div className="flex items-center gap-x-6 bg-gradient-to-r from-rose-900 to-rose-600 px-6 py-2.5 sm:px-3.5 justify-center">
			<p className="text-sm leading-6 text-white">
				<Link to="/registration">
					<strong className="font-semibold">Seems like you haven't set up a merchant account yet. Please click here to proceed with building your account.</strong>
				</Link>
			</p>
		</div>
	);
}

export default Banner;
