import React from 'react';
import {cn} from "../utils/style";

function Button({type, children, onClick, secondary, className, disabled}) {
	return (<button
		onClick={e => {
			e.preventDefault();
			onClick(e);
		}}
		type={type || 'button'}
		disabled={disabled || false}
		className={cn(
			"block rounded-md text-sm px-3 py-2 text-center font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500",
			{
				'bg-gradient-to-br from-gray-500 to-gray-600 hover:from-gray-400 hover:to-gray-500': secondary,
				'bg-gradient-to-br from-secondary-500 to-primary-600 hover:from-secondary-400 hover:to-primary-500': !secondary
			},
			className,
			{
				'!opacity-40': disabled
			}
		)}
	>
		{children}
	</button>);
}

export default Button;