import React from 'react';
import axios from 'axios';
import {GAID_API_URL} from './env';

export const http = axios.create({
	baseURL: GAID_API_URL,
});

http.interceptors.request.use(
	config => {
		try {
			const xAuth = localStorage.getItem('x-auth');
			if (xAuth) {
				const parsedXAuth = JSON.parse(xAuth);
				const token = parsedXAuth?.state?.token;
				if (token) config.headers['Authorization'] = `Bearer ${token}`;
			}
		} catch (e) {
			//
		}
		return config;
	},
	error => {
		return Promise.reject(error);
	}
);

export const AxiosContext = React.createContext(http);