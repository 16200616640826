import React, {useMemo, useState} from 'react';
import SelectInput from "../../../../components/SelectInput";
import TextInput from "../../../../components/TextInput";
import useReportStore from "../../../../store/report";
import {cn} from "../../../../utils/style";
import Button from "../../../../components/Button";
import {useMutation} from "react-query";
import {apollo} from "../../../../utils/apollo";
import {DOWNLOAD_REPORT, REPORT_SEARCH} from "../../../../utils/graphql";
import SlashdataReportView from "../views/slashdata-report-view";
import PaginationFull from "../../../../components/PaginationFull";
import {toast} from "react-toastify";

function SlashdataReportForm({integration}) {
    const [page, setPage] = useState(1);

    const {
        memberAdditionDateStart,
        memberAdditionDateEnd,

        policyNumber,
        policyIssueDate,
        policyStartDate,
        policyExpiryDate,
        policyType,
        policyOwnerType,
        policyOwnerName,
        policyOwnerId,
        planName,

        memberRefNo,
        enrolmentIssueDate,
        enrolmentStartDate,
        unifiedNo,
        emiratesIdNo,
        visaFileNo,
        birthCertNo,
        passportNo,
        gender,
        nationalityCode,
        dateOfBirth,
        fullNameEn,
        firstNameEn,
        middleNameEn,
        lastNameEn,
        fullNameAr,
        firstNameAr,
        middleNameAr,
        lastNameAr,
        maritalStatus,
        relationWithSponsor,
        memberIdType,
        memberType,
        sponsorIdNo,
        sponsorIdType,
        membershipCardNo,
        salaryBand,
        occupationDesc,
        emiratesOfVisaCode,
        emiratesOfLivingCode,
        updateMember,
        resetReportForm,
    } = useReportStore();

    const reportSearchMutation = useMutation({
        mutationKey: ['reportSearch', {page}],
        mutationFn: async (variables) => {
            const {data} = await apollo.mutate({
                mutation: REPORT_SEARCH,
                variables: {
                    page: variables?.page || page,
                    input: {
                        integrationUuid: integration?.integrationUuid,
                        memberAdditionDateStart,
                        memberAdditionDateEnd,
                        policyNumber,
                        policyIssueDate,
                        policyStartDate,
                        policyExpiryDate,
                        policyType,
                        policyOwnerType,
                        policyOwnerName,
                        policyOwnerId,
                        planName,
                        memberRefNo,
                        enrolmentIssueDate,
                        enrolmentStartDate,
                        unifiedNo,
                        emiratesIdNo,
                        visaFileNo,
                        birthCertNo,
                        passportNo,
                        gender,
                        nationalityCode,
                        dateOfBirth,
                        fullNameEn,
                        firstNameEn,
                        middleNameEn,
                        lastNameEn,
                        fullNameAr,
                        firstNameAr,
                        middleNameAr,
                        lastNameAr,
                        maritalStatus,
                        relationWithSponsor,
                        memberIdType,
                        memberType,
                        sponsorIdNo,
                        sponsorIdType,
                        membershipCardNo,
                        salaryBand,
                        occupationDesc,
                        emiratesOfVisaCode,
                        emiratesOfLivingCode,
                    }
                },
            });
            return data?.reportSearch;
        },
        // onError: ((error) => {
        //     if (error.graphQLErrors) {
        //         error.graphQLErrors.map((item) => console.log(item));
        //     }
        //     recaptcha.current?.reset()
        //     setLoading(false)
        // }),
        onSuccess: async ({data}) => {
            if (data && data?.length === 0) {
                toast.warning("No members found")
            }
        },
        // onSettled: async () => {
        //     setLoading(false)
        //     recaptcha?.current?.reset()
        // },
    })

    const downloadReportMutation = useMutation({
        mutationKey: 'downloadReport',
        mutationFn: async (variables) => {
            const {data} = await apollo.mutate({
                mutation: DOWNLOAD_REPORT,
                variables: {
                    input: {
                        integrationUuid: integration?.integrationUuid,
                        memberAdditionDateStart,
                        memberAdditionDateEnd,
                        policyNumber,
                        policyIssueDate,
                        policyStartDate,
                        policyExpiryDate,
                        policyType,
                        policyOwnerType,
                        policyOwnerName,
                        policyOwnerId,
                        planName,
                        memberRefNo,
                        enrolmentIssueDate,
                        enrolmentStartDate,
                        unifiedNo,
                        emiratesIdNo,
                        visaFileNo,
                        birthCertNo,
                        passportNo,
                        gender,
                        nationalityCode,
                        dateOfBirth,
                        fullNameEn,
                        firstNameEn,
                        middleNameEn,
                        lastNameEn,
                        fullNameAr,
                        firstNameAr,
                        middleNameAr,
                        lastNameAr,
                        maritalStatus,
                        relationWithSponsor,
                        memberIdType,
                        memberType,
                        sponsorIdNo,
                        sponsorIdType,
                        membershipCardNo,
                        salaryBand,
                        occupationDesc,
                        emiratesOfVisaCode,
                        emiratesOfLivingCode,
                    }
                },
            });
            return data?.downloadReport;
        },
        // onError: ((error) => {
        //     if (error.graphQLErrors) {
        //         error.graphQLErrors.map((item) => console.log(item));
        //     }
        // }),
        onSuccess: async (data) => {
            if (data && data.startsWith('http')) {
                window.location = data;
                toast.success('File generated successfully');
            } else {
                toast.warning('Something went wrong!');
            }
        },
        // onSettled: async () => {
        //     setLoading(false)
        //     recaptcha?.current?.reset()
        // },
    })
    const members = useMemo(() => reportSearchMutation?.isSuccess ? reportSearchMutation?.data : null, [reportSearchMutation]);

    return (<div>

            <div className={cn(`grid gap-x-6 gap-y-8 grid-cols-12`)}>

                <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                    <TextInput name="memberAdditionDateStart" label="Member Addition Date Start"
                               id="memberAdditionDateStart"
                               type="datetime-local" get={memberAdditionDateStart}
                               set={v => updateMember('memberAdditionDateStart', v)}
                               format="yyyy-MM-dd"
                               required={true}/>
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                    <TextInput name="memberAdditionDateEnd" label="Member Addition Date End" id="memberAdditionDateEnd"
                               type="datetime-local" get={memberAdditionDateEnd}
                               set={v => updateMember('memberAdditionDateEnd', v)}
                               format="yyyy-MM-dd"
                               required={true}/>
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                    <TextInput name="policy_number" label="Policy Number" id="policy_number" type="text"
                               get={policyNumber}
                               set={v => updateMember('policyNumber', v)}
                               required={false}
                    />
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                    <TextInput name="policy_issue_date" label="Policy Issue Date" id="policy_issue_date"
                               type="datetime-local" get={policyIssueDate}
                               set={v => updateMember('policyIssueDate', v)}
                               format="yyyy-MM-dd"
                               required={false}/>
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                    <TextInput name="policy_start_date" label="Policy Start Date" id="policy_start_date"
                               type="datetime-local" get={policyStartDate}
                               set={v => updateMember('policyStartDate', v)}
                               format="yyyy-MM-dd"
                               required={false}/>
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                    <TextInput name="policy_expiry_date" label="Policy Expiry Date" id="policy_expiry_date"
                               type="datetime-local" get={policyExpiryDate}
                               set={v => updateMember('policyExpiryDate', v)}
                               format="yyyy-MM-dd"
                               required={false}/>
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                    <SelectInput name="policy_type" label="Policy Type" id="policy_type" get={policyType}
                                 set={v => updateMember('policyType', v)}
                                 required={false}>
                        <option value=""></option>
                        <option value="1">Individual Or Family</option>
                        <option value="2">Domestic Worker</option>
                        <option value="3">Company</option>
                        <option value="4">Government</option>
                        <option value="5">Medical Facility</option>
                        <option value="6">Determination People</option>
                        <option value="7">Thiqa Plan</option>
                    </SelectInput>
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                    <SelectInput name="policy_owner_type" label="Policy Owner Type" id="policy_owner_type"
                                 get={policyOwnerType}
                                 set={v => updateMember('policyOwnerType', v)}
                                 required={false}>
                        <option value=""></option>
                        <option value="1">Principal</option>
                        <option value="2">Company</option>
                        <option value="3">Government</option>
                    </SelectInput>
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                    <TextInput name="policy_owner_name" label="Policy Owner Name" id="policy_owner_name" type="text"
                               get={policyOwnerName}
                               set={v => updateMember('policyOwnerName', v)}
                               required={false}
                    />
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                    <TextInput name="policy_owner_id" label="Policy Owner Id" id="policy_owner_id" type="text"
                               get={policyOwnerId}
                               set={v => updateMember('policyOwnerId', v)}
                               required={false}
                    />
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                    <TextInput name="plan_name" label="Plan Name" id="plan_name" type="text" get={planName}
                               set={v => updateMember('planName', v)}
                               required={false}
                    />
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                    <TextInput name="memberRefNo" label="Member Ref No" id="memberRefNo" type="text"
                               get={memberRefNo}
                               set={v => updateMember('memberRefNo', v)} required={false}
                    />
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                    <TextInput name="enrolmentIssueDate" label="Enrolment Issue Date" id="enrolmentIssueDate"
                               type="datetime-local" get={enrolmentIssueDate}
                               set={v => updateMember('enrolmentIssueDate', v)}
                               format="yyyy-MM-dd"
                               required={false}/>
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                    <TextInput name="enrolmentStartDate" label="Enrolment Start Date" id="enrolmentStartDate"
                               type="datetime-local" get={enrolmentStartDate}
                               set={v => updateMember('enrolmentStartDate', v)}
                               format="yyyy-MM-dd"
                               required={false}/>
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                    <TextInput name="unifiedNo" label="Unified No" id="unifiedNo" type="text" get={unifiedNo}
                               set={v => updateMember('unifiedNo', v)} required={false}
                    />
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                    <TextInput name="emiratesIdNo" label="Emirates Id No" id="emiratesIdNo" type="text"
                               get={emiratesIdNo}
                               set={v => updateMember('emiratesIdNo', v)} required={false}
                    />
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                    <TextInput name="visaFileNo" label="Visa File No" id="visaFileNo" type="text"
                               get={visaFileNo}
                               set={v => updateMember('visaFileNo', v)} required={false}
                    />
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                    <TextInput name="birthCertNo" label="Birth Cert No" id="birthCertNo" type="text"
                               get={birthCertNo}
                               set={v => updateMember('birthCertNo', v)} required={false}
                    />
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                    <TextInput name="passportNo" label="Passport No" id="passportNo" type="text"
                               get={passportNo}
                               set={v => updateMember('passportNo', v)} required={false}
                    />
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                    <SelectInput name="gender" label="Gender" id="gender"
                                 get={gender}
                                 set={v => updateMember('gender', v)} required={false}>
                        <option value=""></option>
                        <option value="0">Female</option>
                        <option value="1">Male</option>
                    </SelectInput>
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                    <TextInput name="nationalityCode" label="Nationality Code" id="nationalityCode" type="text"
                               get={nationalityCode}
                               set={v => updateMember('nationalityCode', v)} required={false}
                    />
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                    <TextInput name="dateOfBirth" label="Date Of Birth" id="dateOfBirth"
                               type="datetime-local" get={dateOfBirth}
                               set={v => updateMember('dateOfBirth', v)}
                               format="yyyy-MM-dd"
                               required={false}/>
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                    <TextInput name="fullNameEn" label="Full Name En" id="fullNameEn" type="text"
                               get={fullNameEn}
                               set={v => updateMember('fullNameEn', v)} required={false}
                    />
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                    <TextInput name="firstNameEn" label="First Name En" id="firstNameEn" type="text"
                               get={firstNameEn}
                               set={v => updateMember('firstNameEn', v)} required={false}
                    />
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                    <TextInput name="middleNameEn" label="Middle Name En" id="middleNameEn" type="text"
                               get={middleNameEn}
                               set={v => updateMember('middleNameEn', v)} required={false}
                    />
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                    <TextInput name="lastNameEn" label="Last Name En" id="lastNameEn" type="text"
                               get={lastNameEn}
                               set={v => updateMember('lastNameEn', v)} required={false}
                    />
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                    <TextInput name="fullNameAr" label="Full Name Ar" id="fullNameAr" type="text"
                               get={fullNameAr}
                               set={v => updateMember('fullNameAr', v)} required={false}
                    />
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                    <TextInput name="firstNameAr" label="First Name Ar" id="firstNameAr" type="text"
                               get={firstNameAr}
                               set={v => updateMember('firstNameAr', v)} required={false}
                    />
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                    <TextInput name="middleNameAr" label="Middle Name Ar" id="middleNameAr" type="text"
                               get={middleNameAr}
                               set={v => updateMember('middleNameAr', v)} required={false}
                    />
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                    <TextInput name="lastNameAr" label="Last Name Ar" id="lastNameAr" type="text"
                               get={lastNameAr}
                               set={v => updateMember('lastNameAr', v)} required={false}
                    />
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                    <SelectInput name="maritalStatus" label="Marital Status" id="maritalStatus"
                                 get={maritalStatus}
                                 set={v => updateMember('maritalStatus', v)} required={false}>
                        <option value=""></option>
                        <option value="1">Married</option>
                        <option value="2">Single</option>
                    </SelectInput>
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                    <SelectInput name="relationWithSponsor" label="Relation With Sponsor" id="relationWithSponsor"
                                 get={relationWithSponsor}
                                 set={v => updateMember('relationWithSponsor', v)} required={false}>
                        <option value=""></option>
                        <option value="1">Applicant</option>
                        <option value="2">Employee</option>
                        <option value="3">Spouse</option>
                        <option value="4">Child</option>
                        <option value="5">Father</option>
                        <option value="6">Mother</option>
                        <option value="7">Sister</option>
                        <option value="8">Brother</option>
                        <option value="9">Domestic Worker</option>
                        <option value="10">Other</option>
                    </SelectInput>
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                    <SelectInput name="memberIdType" label="Member Id Type" id="memberIdType"
                                 get={memberIdType}
                                 set={v => updateMember('memberIdType', v)} required={false}>
                        <option value=""></option>
                        <option value="1">Unified Number</option>
                        <option value="2">Emirates Id Number</option>
                        <option value="3">Visa File No</option>
                        <option value="4">Passport Number</option>
                        <option value="5">Birth Certificate Number</option>
                    </SelectInput>
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                    <SelectInput name="memberType" label="Member Type" id="memberType" type="text"
                                 get={memberType}
                                 set={v => updateMember('memberType', v)} required={false}>
                        <option value=""></option>
                        <option value="1">Primary</option>
                        <option value="2">Dependent</option>
                    </SelectInput>
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                    <TextInput name="sponsorIdNo" label="Sponsor Id No" id="sponsorIdNo"
                               get={sponsorIdNo}
                               set={v => updateMember('sponsorIdNo', v)} required={false}
                    />
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                    <SelectInput name="sponsorIdType" label="Sponsor Id Type" id="sponsorIdType"
                                 get={sponsorIdType}
                                 set={v => updateMember('sponsorIdType', v)} required={false}>
                        <option value=""></option>
                        <option value="1">Unified Number</option>
                        <option value="2">Emirates Id Number</option>
                        <option value="3">Visa File No</option>
                        <option value="4">Passport No</option>
                    </SelectInput>
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                    <TextInput name="membershipCardNo" label="Membership Card No" id="membershipCardNo" type="text"
                               get={membershipCardNo}
                               set={v => updateMember('membershipCardNo', v)} required={false}
                    />
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                    <TextInput name="salaryBand" label="Salary Band" id="salaryBand" type="text"
                               get={salaryBand}
                               set={v => updateMember('salaryBand', v)} required={false}
                    />
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                    <TextInput name="occupationDesc" label="Occupation Desc" id="occupationDesc" type="text"
                               get={occupationDesc}
                               set={v => updateMember('occupationDesc', v)} required={false}
                    />
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                    <SelectInput name="emiratesOfVisaCode" label="Emirates Of Visa Code" id="emiratesOfVisaCode"
                                 get={emiratesOfVisaCode}
                                 set={v => updateMember('emiratesOfVisaCode', v)} required={false}>
                        <option value=""></option>
                        <option value="1">Abu Dhabi</option>
                        <option value="2">Dubai</option>
                        <option value="3">Sharjah</option>
                        <option value="4">Ajman</option>
                        <option value="5">Umm Al Quwain</option>
                        <option value="6">Ras Al Khaimah</option>
                        <option value="7">Fujairah</option>
                    </SelectInput>
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                    <SelectInput name="emiratesOfLivingCode" label="Emirates Of Living Code" id="emiratesOfLivingCode"
                                 get={emiratesOfLivingCode}
                                 set={v => updateMember('emiratesOfLivingCode', v)} required={false}>
                        <option value=""></option>
                        <option value="1">Abu Dhabi</option>
                        <option value="2">Dubai</option>
                        <option value="3">Sharjah</option>
                        <option value="4">Ajman</option>
                        <option value="5">Umm Al Quwain</option>
                        <option value="6">Ras Al Khaimah</option>
                        <option value="7">Fujairah</option>
                    </SelectInput>
                </div>

                <div className="col-span-12 flex gap-3">
                    <Button onClick={() => reportSearchMutation.mutate()}>Generate Report</Button>
                    <Button onClick={() => downloadReportMutation.mutate()}>Download Full Report</Button>
                    {/*<Button onClick={resetReportForm} secondary={true}>Reset Form</Button>*/}
                </div>
            </div>
            <div>
                <SlashdataReportView members={members?.data}/>
                {(reportSearchMutation.isSuccess && members) &&
                    <PaginationFull paginator={members?.paginatorInfo} get={page} set={v => {
                        setPage(v)
                        reportSearchMutation.mutate({page: v});
                    }}/>}

            </div>
        </div>
    );
}

export default SlashdataReportForm;