import {gql} from "@apollo/client";


export const LOGIN = gql`
    mutation Login($input: LoginFormInput!) {
        login(
            input: $input
        ) {
            success
            message
            token
            user {
                userUuid
                name
                email
                active
            }
        }
    }
`;

export const REQUEST_PASSWORD_RESET = gql`
    mutation RequestPasswordReset($input: RequestPasswordResetFormInput!) {
        requestPasswordReset(
            input: $input
        ) {
            message
        }
    }
`;


export const RESET_PASSWORD = gql`
    mutation ResetPassword($input: ResetPasswordFormInput!) {
        resetPassword(
            input: $input
        ) {
            message
        }
    }
`;

// export const SET_TEST_MODE = gql`
//     mutation SetTestMode($enable: Boolean!) {
//         setTestMode(
//             enable: $enable
//         )
//     }
// `;
//
// export const GET_TEST_MODE = gql`
//     query GetTestMode {
//         getTestMode
//     }
// `;
export const GET_ME = gql`
    query {
        me {
            userUuid
            name
            email
            active
        }
    }
`;

export const GET_POLICY_LIST = gql`
    query GetPolicyList(
        $sortBy: PolicyListSortBy
        $sortDirection: SortDirection
        $page: Int
        $first: Int! = 10
        $filter: PolicyListFilterInput
    ) {
        getPolicyList(
            sortBy: $sortBy
            sortDirection: $sortDirection
            page: $page
            first: $first
            filter: $filter
        ) {
            data {
                policyUuid
                policyNumber
                status
                createdAt
                policyStatus
                policyMembersStatus
                user {
                    userUuid
                    name
                    email
                    active
                }
                integration {
                    name
                    integrationUuid
                    integrationType
                    active
                    createdAt
                }
                company {
                    companyUuid
                    name
                    active
                }
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                perPage
                total
            }
        }
    }
`;

export const GET_POLICY_STATUS_COUNT = gql`
    query GetPolicyStatusCount {
        getPolicyStatusCount {
            status
            count
        }
    }
`;

export const GET_FILE_IMPORT_STATUS_COUNT = gql`
    query GetFileImportStatusCount {
        getFileImportStatusCount {
            status
            count
        }
    }
`;


export const IMPORT_POLICY_FILE = gql`
    mutation ImportPolicyFile($input: ImportPolicyFileInput!) {
        importPolicyFile(input: $input) {
            success
            message
            errorLines
        }
    }
`;


export const CREATE_POLICY = gql`
    mutation CreatePolicy($input: CreatePolicyInput!) {
        createPolicy(input: $input)
    }
`;


export const GET_INTEGRATION_LIST = gql`
    query GetIntegrationList {
        getIntegrationList {
            data{
                name
                integrationUuid
                integrationType
                active
                createdAt
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                perPage
                total
            }
        }
    }
`;

export const GET_POLICY_DETAILS = gql`
    query GetPolicyDetails(
        $policyUuid: String!
    ) {
        getPolicyDetails(
            policyUuid: $policyUuid
        ) {
            policyUuid
            user {
                userUuid
                name
            }
            integration {
                integrationUuid
                name
            }
            company {
                companyUuid
                name
            }
            status
            fromApi
            createdAt
            policyStatus
            policyMembersStatus
            policiable {
                ... on SlashDataPolicy {
                    id
                    icRequestRefNo
                    policyNumber
                    policyIssueDate
                    policyStartDate
                    policyExpiryDate
                    policyType
                    policyOwnerType
                    policyOwnerName
                    policyOwnerId
                    planName
                    operationType
                    policyCreationRefNo
                    policyCancellationRefNo
                    cancellationDate
                    integrationStatus
                }
            }
        }
    }
`;

export const GET_SLASHDATA_POLICY_MEMBERS = gql`
    query GetSlashdataPolicyMembers(
        $policyUuid: String!
    ) {
        getSlashdataPolicyMembers(
            policyUuid: $policyUuid
        ) {
            id
            memberRefNo
            enrolmentIssueDate
            enrolmentStartDate
            unifiedNo
            emiratesIdNo
            visaFileNo
            birthCertNo
            passportNo
            gender
            nationalityCode
            dateOfBirth
            fullNameEn
            firstNameEn
            middleNameEn
            lastNameEn
            fullNameAr
            firstNameAr
            middleNameAr
            lastNameAr
            maritalStatus
            relationWithSponsor
            memberIdType
            memberType
            sponsorIdNo
            sponsorIdType
            membershipCardNo
            className
            salaryBand
            occupationDesc
            emiratesOfVisaCode
            emiratesOfLivingCode
            memberCreationRefNo
            memberCancelRefNo
            cancellationDate
            integrationStatus
            status
            errors
            createdAt
            updatedAt
        }
    }
`;

export const GET_FILE_IMPORT_LIST = gql`
    query GetFileImportList(
        $page: Int
        $first: Int! = 10
        $filter: FileImportListFilterInput
    ) {
        getFileImportList(
            page: $page
            first: $first
            filter: $filter
        ) {
            data{
                fileImportUuid
                integration {
                    name
                    integrationUuid
                    integrationType
                    active
                    createdAt
                }
                user {
                    userUuid
                    name
                    email
                    active
                }
                filename
                totalRecords
                importedRecords
                errors
                status
                startedAt
                completedAt
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                perPage
                total
            }
        }
    }
`;

export const CANCEL_POLICY = gql`
    mutation CancelPolicy($policyUuid: String!) {
        cancelPolicy(
            policyUuid: $policyUuid
        )
    }
`;

export const GET_MEMBER_LIST = gql`
    query GetMemberList(
        $sortBy: MemberListSortBy
        $sortDirection: SortDirection
        $page: Int
        $first: Int! = 10
        $filter: MemberListFilterInput
    ) {
        getMemberList(
            sortBy: $sortBy
            sortDirection: $sortDirection
            page: $page
            first: $first
            filter: $filter
        ) {
            data {
                id
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                perPage
                total
            }
        }
    }
`;

export const GET_MEMBER_STATUS_COUNT = gql`
    query GetMemberStatusCount {
        getMemberStatusCount {
            status
            count
        }
    }
`;

export const GET_SLASHDATA_MEMBER = gql`
    query GetSlashdataMember($memberUuid: String!) {
        getSlashdataMember(
            memberUuid: $memberUuid
        ) {
            id
            memberRefNo
            enrolmentIssueDate
            enrolmentStartDate
            unifiedNo
            emiratesIdNo
            visaFileNo
            birthCertNo
            passportNo
            gender
            nationalityCode
            dateOfBirth
            fullNameEn
            firstNameEn
            middleNameEn
            lastNameEn
            fullNameAr
            firstNameAr
            middleNameAr
            lastNameAr
            maritalStatus
            relationWithSponsor
            memberIdType
            memberType
            sponsorIdNo
            sponsorIdType
            membershipCardNo
            className
            salaryBand
            occupationDesc
            emiratesOfVisaCode
            emiratesOfLivingCode
            memberCreationRefNo
            memberCancelRefNo
            cancellationDate
            integrationStatus
            status
            createdAt
            updatedAt
        }
    }
`;


export const REPORT_SEARCH = gql`
    mutation ReportSearch(
        $page: Int
        $first: Int! = 10
        $input: ReportSearchInput!
    ) {
        reportSearch(
            page: $page
            first: $first
            input: $input
        ) {
            data {
                slashDataPolicy {
                    policyNumber
                    policyIssueDate
                    policyStartDate
                    policyExpiryDate
                    policyType
                    policyOwnerType
                    policyOwnerName
                    policyOwnerId
                    planName
                }
                memberAdditionDate: createdAt
                memberRefNo
                enrolmentIssueDate
                enrolmentStartDate
                unifiedNo
                emiratesIdNo
                visaFileNo
                birthCertNo
                passportNo
                gender
                nationalityCode
                dateOfBirth
                fullNameEn
                firstNameEn
                middleNameEn
                lastNameEn
                fullNameAr
                firstNameAr
                middleNameAr
                lastNameAr
                maritalStatus
                relationWithSponsor
                memberIdType
                memberType
                sponsorIdNo
                sponsorIdType
                membershipCardNo
                salaryBand
                occupationDesc
                emiratesOfVisaCode
                emiratesOfLivingCode
                integrationStatus
                status
                className
                memberCreationRefNo
                memberCancelRefNo
                cancellationDate
                createdAt
                updatedAt
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                perPage
                total
            }
        }
    }
`;

export const DOWNLOAD_REPORT = gql`
    mutation DownloadReport(
        $input: ReportSearchInput!
    ) {
        downloadReport(
            input: $input
        )
    }
`;

export const RECHECK_MEMBER_STATUS = gql`
    mutation ReCheckMemberStatus($policyUuid: String!) {
        reCheckMemberStatus(policyUuid: $policyUuid)
    }
`;


export const CANCEL_SLASHDATA_MEMBER = gql`
    mutation CancelSlashdataMember($slashdataMemberUuid: String!) {
        cancelSlashdataMember(slashdataMemberUuid: $slashdataMemberUuid)
    }
`;
