import React, {Fragment, useEffect, useState} from 'react'
import {Dialog, Menu, Transition} from '@headlessui/react'
import {
    Bars3Icon,
    HomeIcon,
    UserGroupIcon,
    InboxStackIcon,
    PresentationChartLineIcon,
    InboxArrowDownIcon,
    XMarkIcon, DocumentDuplicateIcon
} from '@heroicons/react/24/outline'
import {ChevronDownIcon} from '@heroicons/react/20/solid'
import Logo from "../components/Logo";
import useAuthStore from "../store/auth";
import {Link, useLocation, useNavigate} from "react-router-dom";
// import Language from "../components/Language";
import {useTranslation} from "react-i18next";
import {clsx} from "clsx";
import Banner from "../components/Banner";
// import TestMode from "../components/TestMode";
import UserAvatar from "../components/UserAvatar";
import {useQuery} from "react-query";
import {apollo} from "../utils/apollo";
import {GET_ME} from "../utils/graphql";

function DashboardLayout({children}) {
    const {token, user, company, roles, setUserAuth, clearToken} = useAuthStore()
    const {t} = useTranslation()
    const navigate = useNavigate();
    const location = useLocation();

    useQuery(
        'account_details',
        async () => {
            const {data} = await apollo.query({
                query: GET_ME,
                variables: {},
            });
            return data?.me;
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: true,
            staleTime: 300000, // 5 minutes
            cacheTime: 600000, // 10 minutes
            onSuccess: (data) => {
                if (!data?.active) {
                    clearToken();
                    window.location.href = process.env.REACT_APP_GAID_URL + `/logout`;
                }
            },
            onError: () => {
                clearToken();
                window.location.href = process.env.REACT_APP_GAID_URL + `/logout`;
            }
        }
    );

    useEffect(() => {
        if (!token) navigate("/login");
    });

    const [sidebarOpen, setSidebarOpen] = useState(false)

    const navigation = [
        {name: t('navigation.dashboard'), href: '/dashboard', icon: HomeIcon},
        // {name: t('navigation.summary'), href: '/summary', icon: PresentationChartLineIcon},
        // {name: t('navigation.registration'), href: '/company/registration', icon: DocumentCheckIcon},
        // {name: t('navigation.invoices'), href: '/company/invoices', icon: DocumentDuplicateIcon},
        {name: t('navigation.policies'), href: '/policies', icon: InboxStackIcon},
        {name: t('navigation.import_members'), href: '/policy/import', icon: InboxArrowDownIcon},
        {name: t('navigation.reports'), href: '/reports', icon: DocumentDuplicateIcon},
        // {name: t('navigation.members'), href: '/members', icon: UserGroupIcon},
        // {name: t('navigation.members'), href: '/members', icon: InboxStackIcon},
        // {name: t('navigation.subscriptions'), href: '/company/subscriptions', icon: BanknotesIcon},
        // {
        //     name: t('navigation.settlements'),
        //     href: '/company/settlements',
        //     icon: CurrencyDollarIcon,
        //     onlyMerchant: true
        // },
    ]
    const company_navigation = [
        // {id: 1, name: t('navigation.registration'), href: '/company/registration', initial: 'R'},
        // {id: 3, name: t('company_navigation.sub_users'), href: '/company/sub-users', initial: 'S'},
        // {id: 4, name: t('company_navigation.api_docs'), href: '/company/docs/v1', initial: 'A'},
    ]
    const userNavigation = [
        {name: 'Your profile', href: '/profile'},
        {name: 'Sign out', href: '/logout'},
    ]

    return (
        <div>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-900/80"/>
                    </Transition.Child>

                    <div className="fixed inset-0 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                        <button type="button" className="-m-2.5 p-2.5"
                                                onClick={() => setSidebarOpen(false)}>
                                            <span className="sr-only">Close sidebar</span>
                                            <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true"/>
                                        </button>
                                    </div>
                                </Transition.Child>
                                {/* Sidebar component, swap this element with another sidebar if you like */}
                                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                                    <div className="flex h-16 shrink-0 items-center justify-center">
                                        <Logo/>
                                    </div>
                                    <nav className="flex flex-1 flex-col">
                                        <ul className="flex flex-1 flex-col gap-y-7">
                                            <li>
                                                <ul className="-mx-2 space-y-1">
                                                    {navigation.map((item) => (
                                                        <li key={item.name}
                                                            className={clsx((item?.onlyMerchant && !roles?.includes("company")) ? 'hidden' : '')}>
                                                            <Link
                                                                to={item.href}
                                                                className={clsx(
                                                                    location.pathname === item.href
                                                                        ? 'bg-primary-50 text-primary-500'
                                                                        : 'text-gray-700 hover:text-primary-500 hover:bg-gray-50',
                                                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                                                )}
                                                            >
                                                                <item.icon
                                                                    className={clsx(
                                                                        location.pathname === item.href ? 'text-primary-500' : 'text-gray-400 group-hover:text-primary-500',
                                                                        'h-6 w-6 shrink-0'
                                                                    )}
                                                                    aria-hidden="true"
                                                                />
                                                                {item.name}
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                            {roles?.includes("company") && <li className="">
                                                <div
                                                    className="text-xs font-semibold leading-6 text-gray-400">{t('company_navigation.section_title')}</div>
                                                <ul className="-mx-2 mt-2 space-y-1">
                                                    {company_navigation.map((team) => (
                                                        <li key={team.name}>
                                                            <Link
                                                                to={team.href}
                                                                className={clsx(
                                                                    team.current
                                                                        ? 'bg-primary-50 text-primary-500'
                                                                        : 'text-gray-700 hover:text-primary-500 hover:bg-gray-50',
                                                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                                                )}
                                                            >
																	<span
                                                                        className={clsx(
                                                                            team.current
                                                                                ? 'text-primary-500 border-primary-500'
                                                                                : 'text-gray-400 border-gray-200 group-hover:border-primary-500 group-hover:text-primary-500',
                                                                            'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white'
                                                                        )}
                                                                    >
																		{team.initial}
																	</span>
                                                                <span className="truncate">{team.name}</span>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>}
                                            <li className="mt-auto">
                                                {/*<Link*/}
                                                {/*    to="/company/settings"*/}
                                                {/*    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-primary-500"*/}
                                                {/*>*/}
                                                {/*    <Cog6ToothIcon*/}
                                                {/*        className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-primary-500"*/}
                                                {/*        aria-hidden="true"*/}
                                                {/*    />*/}
                                                {/*    {t('_.settings')}*/}
                                                {/*</Link>*/}
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div
                    className="flex grow flex-col gap-y-5 overflow-y-auto ltr:border-r rtl:border-l border-gray-200 bg-white px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center justify-center">
                        <Logo/>
                    </div>
                    <nav className="flex flex-1 flex-col">
                        <ul className="flex flex-1 flex-col gap-y-7">
                            <li>
                                <ul className="-mx-2 space-y-1">
                                    {navigation.map((item) => (
                                        <li key={item.name}
                                            className={clsx((item?.onlyMerchant && !roles?.includes("company")) ? 'hidden' : '')}>
                                            <Link
                                                to={item.href}
                                                className={clsx(
                                                    location.pathname === item.href
                                                        ? 'bg-primary-50 text-primary-500'
                                                        : 'text-gray-700 hover:text-primary-500 hover:bg-gray-50',
                                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                                )}
                                            >
                                                <item.icon
                                                    className={clsx(
                                                        location.pathname === item.href ? 'text-primary-500' : 'text-gray-400 group-hover:text-primary-500',
                                                        'h-6 w-6 shrink-0'
                                                    )}
                                                    aria-hidden="true"
                                                />
                                                {item.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                            {roles?.includes("company") && <li className="">
                                <div
                                    className="text-xs font-semibold leading-6 text-gray-400">{t('company_navigation.section_title')}</div>
                                <ul className="-mx-2 mt-2 space-y-1">
                                    {company_navigation.map((item) => (
                                        <li key={item.name}>
                                            <Link
                                                to={item.href}
                                                className={clsx(
                                                    location.pathname === item.href
                                                        ? 'bg-primary-50 text-primary-500'
                                                        : 'text-gray-700 hover:text-primary-500 hover:bg-gray-50',
                                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                                )}
                                            >
													<span
                                                        className={clsx(
                                                            location.pathname === item.href
                                                                ? 'text-primary-500 border-primary-500'
                                                                : 'text-gray-400 border-gray-200 group-hover:border-primary-500 group-hover:text-primary-500',
                                                            'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white'
                                                        )}
                                                    >
														{item.initial}
													</span>
                                                <span className="truncate">{item.name}</span>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </li>}
                            <li className="mt-auto">
                                {/*<Link*/}
                                {/*    to="/company/settings"*/}
                                {/*    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-primary-500"*/}
                                {/*>*/}
                                {/*    <Cog6ToothIcon*/}
                                {/*        className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-primary-500"*/}
                                {/*        aria-hidden="true"*/}
                                {/*    />*/}
                                {/*    {t('_.settings')}*/}
                                {/*</Link>*/}
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

            <div className="ltr:lg:pl-72 rtl:lg:pr-72">
                <div className="sticky top-0 z-40 w-full border-b border-gray-200 bg-white lg:shadow-none">
                    <div className="lg:mx-auto lg:max-w-7xl lg:px-8">
                        <div className="flex h-16 items-center gap-x-4 px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-0">
                            <button
                                type="button"
                                className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
                                onClick={() => setSidebarOpen(true)}
                            >
                                <span className="sr-only">Open sidebar</span>
                                <Bars3Icon className="h-6 w-6" aria-hidden="true"/>
                            </button>

                            {/* Separator */}
                            <div className="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true"/>

                            <div
                                className="flex flex-1 justify-between sm:justify-auto gap-x-4 self-stretch lg:gap-x-6">
                                <div
                                    className="flex items-center gap-x-4 lg:gap-x-6 text-lg font-bold bg-clip-text print:text-black text-transparent ltr:bg-gradient-to-br rtl:bg-gradient-to-l from-secondary-500 to-primary-600">
                                    <span className="hidden sm:inline">
                                        API Integration Dashboard
                                    </span>
                                </div>
                                {/*<form className="relative hidden sm:flex flex-1" action="#" method="GET">*/}
                                {/*    <label htmlFor="search-field" className="sr-only">*/}
                                {/*        {t('_.search')}*/}
                                {/*    </label>*/}
                                {/*    <MagnifyingGlassIcon*/}
                                {/*        className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"*/}
                                {/*        aria-hidden="true"*/}
                                {/*    />*/}
                                {/*    <input*/}
                                {/*        id="search-field"*/}
                                {/*        className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"*/}
                                {/*        placeholder={`${t('_.search')}...`}*/}
                                {/*        type="search"*/}
                                {/*        name="search"*/}
                                {/*    />*/}
                                {/*</form>*/}
                                <div className="flex items-center gap-x-4 lg:gap-x-6">
                                    {/*<Language className="hidden sm:block"/>*/}
                                    {/*<TestMode/>*/}
                                    {/*<Link to="/notifications" className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">*/}
                                    {/*	<span className="sr-only">View notifications</span>*/}
                                    {/*	<BellIcon className="h-6 w-6" aria-hidden="true"/>*/}
                                    {/*</Link>*/}

                                    {/*<div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200" aria-hidden="true"/>*/}

                                    {/* Profile dropdown */}
                                    <Menu as="div" className="relative">
                                        <Menu.Button className="-m-1.5 flex items-center p-1.5">
                                            <span className="sr-only">Open user menu</span>
                                            <UserAvatar className="h-8 w-8 rounded-full bg-gray-50"/>
                                            <span className="hidden lg:flex lg:items-center">
												<span
                                                    className="ltr:ml-4 rtl:mr-4 text-sm font-semibold leading-6 text-gray-900 capitalize"
                                                    aria-hidden="true">
													{user?.name}
												</span>
												<ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400"
                                                                 aria-hidden="true"/>
											</span>
                                        </Menu.Button>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items
                                                className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                                {userNavigation.map((item) => (
                                                    <Menu.Item key={item.name}>
                                                        {({active}) => (
                                                            <Link
                                                                to={item.href}
                                                                className={clsx(
                                                                    active ? 'bg-gray-50' : '',
                                                                    'block px-3 py-1 text-sm leading-6 text-gray-900'
                                                                )}
                                                            >
                                                                {item.name}
                                                            </Link>
                                                        )}
                                                    </Menu.Item>
                                                ))}
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {!company?.active && roles?.includes("company") && <Banner/>}
                <main className="py-2 sm:py-10 sm:px-4 3xl:px-0">
                    <div
                        className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 bg-white/50 pt-4 pb-8 rounded-lg shadow">{children}</div>
                </main>
            </div>
        </div>
    )
}

export default DashboardLayout;