import React from 'react';

const PolicyEdit = () => {
    return (
        <div>
            PolicyUpdate
        </div>
    );
};

export default PolicyEdit;
