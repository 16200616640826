import {toast} from "react-toastify";

export const handleXErrorMessage = (message) => {
    switch (message) {
        case 'X_ERROR/POLICY_NUMBER_ALREADY_EXISTS':
            toast.error('Policy number already exists');
            break;
        default:
            console.log(`GraphQL error: ${message}`);
            break;
    }
}