import React, {useEffect, useState} from 'react';
import DashboardLayout from "../../../layouts/DashboardLayout";
import PageHeader from "../../../components/PageHeader";
import {FunnelIcon} from "@heroicons/react/24/outline";
import IconButton from "../../../components/IconButton";
import {useTranslation} from "react-i18next";
import {useQuery} from "react-query";
import ButtonInternalLink from "../../../components/ButtonInternalLink";
import SelectInput from "../../../components/SelectInput";
import Loading from "../../../components/Loading";
import {apollo} from "../../../utils/apollo";
import {GET_MEMBER_LIST, GET_MEMBER_STATUS_COUNT} from "../../../utils/graphql";
import Tabs from "../../../components/Tabs";
import {MEMBER_LIST_STATUS_FOR_FILTER} from "../../../utils/constants";
// import CompactMemberList from "./components/CompactMemberList";
import PaginationFull from "../../../components/PaginationFull";
import TextInput from "../../../components/TextInput";

function MemberList() {
    const {t} = useTranslation();
    const [sort, setSort] = useState({field: 'id', isAscending: false});
    const [page, setPage] = useState(1);
    const [showFilter, setShowFilter] = useState(false);
    const [filter, setFilter] = useState({status: '', test: '', memberUuid: ''})
    const [statusTabs, setStatusTabs] = useState(MEMBER_LIST_STATUS_FOR_FILTER)
    useEffect(() => setPage(1), [filter, sort]);

    useQuery('member_status_count', async () => {
        const {data} = await apollo.query({
            query: GET_MEMBER_STATUS_COUNT, variables: {},
        });
        return data?.getMemberStatusCount;
    }, {
        keepPreviousData: true, onSettled: (data) => {
            if (data && Array.isArray(data)) {
                let updatedStatusTabs = [...statusTabs];
                data.forEach((item) => {
                    let foundIndex = updatedStatusTabs.findIndex(tab => tab.column === item.status);
                    if (foundIndex !== -1) {
                        updatedStatusTabs[foundIndex].count = parseInt(item.count);
                    }
                });
                setStatusTabs(updatedStatusTabs);
            }
        },
    });

    const fetchMembers = useQuery(['member_list', {sort, page, filter}], async () => {
        const {data} = await apollo.query({
            query: GET_MEMBER_LIST, variables: {
                sortBy: sort.field?.toUpperCase(),
                sortDirection: sort.isAscending ? "ASC" : "DESC",
                page,
                first: 15,
                filter: {
                    status: filter.status, // test: filter.test,
                    memberUuid: filter.memberUuid,
                }
            },
        });
        return data?.getMemberList || null;
    }, {keepPreviousData: false, refetchInterval: 10000},);

    const records = fetchMembers?.data || null;

    const handleChangeSort = (field) => {
        if (sort.field === field) {
            setSort(prevState => ({...prevState, isAscending: !prevState.isAscending}));
        } else {
            setSort({field: field, isAscending: true});
        }
    };

    return (<DashboardLayout>
        <PageHeader title={t('member.page_title')} description={t('member.page_description')}>
            <div className="flex items-center gap-3 flex-wrap">
                <IconButton onClick={() => setShowFilter(!showFilter)}>
                    <FunnelIcon className="w-5 h-5 text-primary-500"/>
                </IconButton>
                <div className="flex items-center gap-3 flex-wrap">
                    <ButtonInternalLink
                        href="/member/import">{t('member.import_members')}</ButtonInternalLink>
                </div>
                <div className="flex items-center gap-3 flex-wrap">
                    <ButtonInternalLink
                        href="/member/create">{t('member.create_member')}</ButtonInternalLink>
                </div>
            </div>
        </PageHeader>
        {showFilter &&
            <div className="my-4 shadow-md bg-white border rounded-lg px-4 py-4 grid grid-cols-1 md:grid-cols-12 gap-4">
                <div className="col-span-3">
                    <TextInput name="status-filter" label="Member Id / Reference" id="status-filter"
                               get={filter.memberUuid}
                               set={v => setFilter({...filter, memberUuid: v})}/>
                </div>
                <div className="col-span-3">
                    <SelectInput name="status-filter" label="Status" id="status-filter" get={filter.status}
                                 set={v => setFilter({...filter, status: v})}>
                        {MEMBER_LIST_STATUS_FOR_FILTER.map(s => <option key={s.column}
                                                                        value={s.column}>{s.name} {s.count ? `(${s.count})` : ''}</option>)}
                    </SelectInput>
                </div>
            </div>}
        <Tabs tabs={statusTabs} get={filter?.status} set={v => setFilter({...filter, status: v})}/>
        {fetchMembers.isLoading && <Loading/>}
        {(fetchMembers.isSuccess && records) && <>
            {/*<CompactMemberList*/}
            {/*    handleChangeSort={handleChangeSort}*/}
            {/*    records={records}*/}
            {/*    sort={sort}*/}
            {/*/>*/}
            {/*<Pagination paginator={records?.paginatorInfo} get={page} set={setPage}/>*/}
            <PaginationFull paginator={records?.paginatorInfo} get={page} set={setPage}/>
        </>}
    </DashboardLayout>);
}


export default MemberList;

