import React from 'react';

function CheckBox({label, id, name, get, set}) {
	return (
		<div className="flex items-center">
			<input
				id={id}
				name={name}
				type="checkbox"
				defaultChecked={get}
				onChange={e => set(e.target.checked)}
				className="h-4 w-4 rounded border-gray-300 text-primary-500 focus:ring-primary-500"
			/>
			<label htmlFor={id} className="ltr:ml-3 rtl:mr-3 block text-sm leading-6 text-gray-900">
				{label}
			</label>
		</div>
	);
}

export default CheckBox;