import React, {useMemo, useState} from 'react';
import SelectInput from "../../../../components/SelectInput";
import TextInput from "../../../../components/TextInput";
import SimpleTabs from "../../../../components/SimpleTabs";
import usePolicyCreateStore from "../../../../store/policy-create";
import CheckBox from "../../../../components/CheckBox";
import {cn} from "../../../../utils/style";
import Button from "../../../../components/Button";
import {useMutation, useQueryClient} from "react-query";
import {apollo} from "../../../../utils/apollo";
import {CREATE_POLICY} from "../../../../utils/graphql";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

function SlashdataPolicyCreateForm({integration}) {
    const navigate = useNavigate();
    const queryClient = useQueryClient()
    const [errors, setErrors] = useState([]);

    const {
        policyNumber,
        policyIssueDate,
        policyStartDate,
        policyExpiryDate,
        policyType,
        policyOwnerType,
        policyOwnerName,
        policyOwnerId,
        planName,
        members,
        setPolicyNumber,
        setPolicyIssueDate,
        setPolicyStartDate,
        setPolicyExpiryDate,
        setPolicyType,
        setPolicyOwnerType,
        setPolicyOwnerName,
        setPolicyOwnerId,
        setPlanName,
        resetPolicyCreateForm,
    } = usePolicyCreateStore();

    const createPolicyMutation = useMutation({
        mutationFn: async () => {
            setErrors([]);
            const {data} = await apollo.mutate({
                mutation: CREATE_POLICY,
                variables: {
                    input: {
                        integrationUuid: integration?.integrationUuid,
                        policyNumber,
                        policyIssueDate,
                        policyStartDate,
                        policyExpiryDate,
                        policyType,
                        policyOwnerType,
                        policyOwnerName,
                        policyOwnerId,
                        planName,
                        members: members.filter(m => m.enable),
                    }
                },
            });
            return data?.createPolicy;
        },
        onError: ((error) => {

            if (error.graphQLErrors) {
                let formattedErrors = [];
                error.graphQLErrors.forEach(error => {
                    if (error.extensions && error.extensions.validation) {
                        const validationErrors = error.extensions.validation;
                        Object.keys(validationErrors).forEach(memberIndex => {
                            const memberErrors = validationErrors[memberIndex];
                            const indexParts = memberIndex.split('.');
                            if (memberIndex.startsWith('input.members.')) {
                                const memberNumber = indexParts?.length > 2 ? indexParts[2] : null;
                                if (memberNumber && members[memberNumber]?.enable) {
                                    formattedErrors.push(`Member ${(1 + parseInt(memberNumber))}: ${memberErrors}`)
                                }
                            } else {
                                formattedErrors.push(`${indexParts[indexParts.length - 1]}: ${memberErrors}`)
                            }
                        });
                    }
                });
                setErrors(formattedErrors);
                if (formattedErrors.length > 0) {
                    toast.error(formattedErrors[0]);
                }
            }
        }),
        onSuccess: async (data) => {
            if (data) {
                toast.success('Policy created successfully');
                queryClient.invalidateQueries(['policy_list']).then(() => {
                    setTimeout(() => navigate("/policies"), 2000);
                });
            } else {
                toast.error('Failed to create policy');
            }
        },
        // onSettled: async (data) => {
        //     console.log(data)
        // },
    })

    return (
        <div className="mt-8 grid gap-x-6 gap-y-8 grid-cols-12">

            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="policy_number" label="Policy Number" id="policy_number" type="text" get={policyNumber}
                           set={setPolicyNumber} required={true}
                />
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="policy_issue_date" label="Policy Issue Date" id="policy_issue_date"
                           type="datetime-local" get={policyIssueDate}
                           set={setPolicyIssueDate}
                           format="yyyy-MM-dd"
                           required={true}/>
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="policy_start_date" label="Policy Start Date" id="policy_start_date"
                           type="datetime-local" get={policyStartDate}
                           set={setPolicyStartDate}
                           format="yyyy-MM-dd"
                           required={true}/>
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="policy_expiry_date" label="Policy Expiry Date" id="policy_expiry_date"
                           type="datetime-local" get={policyExpiryDate}
                           set={setPolicyExpiryDate}
                           format="yyyy-MM-dd"
                           required={true}/>
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <SelectInput name="policy_type" label="Policy Type" id="policy_type" get={policyType}
                             set={setPolicyType} required={true}>
                    <option value=""></option>
                    <option value="1">Individual Or Family</option>
                    <option value="2">Domestic Worker</option>
                    <option value="3">Company</option>
                    <option value="4">Government</option>
                    <option value="5">Medical Facility</option>
                    <option value="6">Determination People</option>
                    <option value="7">Thiqa Plan</option>
                </SelectInput>
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <SelectInput name="policy_owner_type" label="Policy Owner Type" id="policy_owner_type"
                             get={policyOwnerType}
                             set={setPolicyOwnerType} required={true}>
                    <option value=""></option>
                    <option value="1">Principal</option>
                    <option value="2">Company</option>
                    <option value="3">Government</option>
                </SelectInput>
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="policy_owner_name" label="Policy Owner Name" id="policy_owner_name" type="text"
                           get={policyOwnerName} set={setPolicyOwnerName} required={true}
                />
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="policy_owner_id" label="Policy Owner Id" id="policy_owner_id" type="text"
                           get={policyOwnerId}
                           set={setPolicyOwnerId} required={true}
                />
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="plan_name" label="Plan Name" id="plan_name" type="text" get={planName}
                           set={setPlanName} required={true}
                />
            </div>


            <div className="col-span-12 flex flex-col gap-3">
                <MembersSection/>
            </div>

            <div className="col-span-12 flex gap-3">
                <Button onClick={() => createPolicyMutation.mutate()}>Create Policy</Button>
                {/*<Button onClick={resetPolicyCreateForm} secondary={true}>Reset Form</Button>*/}
            </div>

            {errors?.length > 0 && <>
                <div className="col-span-12 gap-3 rounded-lg">
                    <h3 className="mb-2 text-lg font-semibold text-red-500">Validation Errors</h3>
                    {errors?.map((error, index) => index < 5 ? (
                        <div key={index} className="text-sm text-gray-600">
                            {error}
                        </div>
                    ) : null)}
                </div>
            </>}
        </div>
    );
}

function MembersSection() {
    const [selected, setSelectedMember] = React.useState(0);
    const {members, updateMember} = usePolicyCreateStore();
    const tabs = members.map((m, i) => ({
        name: `Member ${i + 1}`,
        column: i,
        current: selected === i
    }))
    let member = useMemo(() => members[selected], [selected]);

    return <div>
        <h3 className="my-4 text-lg font-semibold">Policy Members</h3>

        <SimpleTabs tabs={tabs} get={selected} set={setSelectedMember}/>

        <div className="mt-2 mb-4">
            <CheckBox key={selected}
                      label="Enable"
                      get={member.enable === true}
                      set={() => updateMember('enable', selected, !member.enable)}
                      id={`enable_member_${selected + 1}`}
                      name={`enable_member_${selected + 1}`}
                      type="checkbox"/>
        </div>

        <div className={cn("flex items-center justify-center h-40 text-gray-500 font-light text-xl", {
            "hidden": member.enable === true
        })}>
            Enable member to create
        </div>

        <div className={cn(`grid gap-x-6 gap-y-8 grid-cols-12`, {
            "hidden": member.enable !== true
        })}>

            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="memberRefNo" label="Member Ref No" id="memberRefNo" type="text"
                           get={member?.memberRefNo}
                           set={v => updateMember('memberRefNo', selected, v)} required={true}
                />
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="enrolmentIssueDate" label="Enrolment Issue Date" id="enrolmentIssueDate"
                           type="datetime-local" get={member?.enrolmentIssueDate}
                           set={v => updateMember('enrolmentIssueDate', selected, v)}
                           format="yyyy-MM-dd"
                           required={true}/>
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="enrolmentStartDate" label="Enrolment Start Date" id="enrolmentStartDate"
                           type="datetime-local" get={member?.enrolmentStartDate}
                           set={v => updateMember('enrolmentStartDate', selected, v)}
                           format="yyyy-MM-dd"
                           required={true}/>
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="unifiedNo" label="Unified No" id="unifiedNo" type="text" get={member?.unifiedNo}
                           set={v => updateMember('unifiedNo', selected, v)} required={true}
                />
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="emiratesIdNo" label="Emirates Id No" id="emiratesIdNo" type="text"
                           get={member?.emiratesIdNo}
                           set={v => updateMember('emiratesIdNo', selected, v)} required={true}
                />
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="visaFileNo" label="Visa File No" id="visaFileNo" type="text" get={member?.visaFileNo}
                           set={v => updateMember('visaFileNo', selected, v)} required={true}
                />
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="birthCertNo" label="Birth Cert No" id="birthCertNo" type="text"
                           get={member?.birthCertNo}
                           set={v => updateMember('birthCertNo', selected, v)} required={true}
                />
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="passportNo" label="Passport No" id="passportNo" type="text" get={member?.passportNo}
                           set={v => updateMember('passportNo', selected, v)} required={true}
                />
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <SelectInput name="gender" label="Gender" id="gender"
                             get={member?.gender}
                             set={v => updateMember('gender', selected, v)} required={true}>
                    <option value=""></option>
                    <option value="0">Female</option>
                    <option value="1">Male</option>
                </SelectInput>
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="nationalityCode" label="Nationality Code" id="nationalityCode" type="text"
                           get={member?.nationalityCode}
                           set={v => updateMember('nationalityCode', selected, v)} required={true}
                />
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="dateOfBirth" label="Date Of Birth" id="dateOfBirth"
                           type="datetime-local" get={member?.dateOfBirth}
                           set={v => updateMember('dateOfBirth', selected, v)}
                           format="yyyy-MM-dd"
                           required={true}/>
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="fullNameEn" label="Full Name En" id="fullNameEn" type="text" get={member?.fullNameEn}
                           set={v => updateMember('fullNameEn', selected, v)} required={true}
                />
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="firstNameEn" label="First Name En" id="firstNameEn" type="text"
                           get={member?.firstNameEn}
                           set={v => updateMember('firstNameEn', selected, v)} required={true}
                />
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="middleNameEn" label="Middle Name En" id="middleNameEn" type="text"
                           get={member?.middleNameEn}
                           set={v => updateMember('middleNameEn', selected, v)} required={true}
                />
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="lastNameEn" label="Last Name En" id="lastNameEn" type="text" get={member?.lastNameEn}
                           set={v => updateMember('lastNameEn', selected, v)} required={true}
                />
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="fullNameAr" label="Full Name Ar" id="fullNameAr" type="text" get={member?.fullNameAr}
                           set={v => updateMember('fullNameAr', selected, v)} required={true}
                />
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="firstNameAr" label="First Name Ar" id="firstNameAr" type="text"
                           get={member?.firstNameAr}
                           set={v => updateMember('firstNameAr', selected, v)} required={true}
                />
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="middleNameAr" label="Middle Name Ar" id="middleNameAr" type="text"
                           get={member?.middleNameAr}
                           set={v => updateMember('middleNameAr', selected, v)} required={true}
                />
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="lastNameAr" label="Last Name Ar" id="lastNameAr" type="text" get={member?.lastNameAr}
                           set={v => updateMember('lastNameAr', selected, v)} required={true}
                />
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <SelectInput name="maritalStatus" label="Marital Status" id="maritalStatus"
                             get={member?.maritalStatus}
                             set={v => updateMember('maritalStatus', selected, v)} required={true}>
                    <option value=""></option>
                    <option value="1">Married</option>
                    <option value="2">Single</option>
                </SelectInput>
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <SelectInput name="relationWithSponsor" label="Relation With Sponsor" id="relationWithSponsor"
                             get={member?.relationWithSponsor}
                             set={v => updateMember('relationWithSponsor', selected, v)} required={true}>
                    <option value=""></option>
                    <option value="1">Applicant</option>
                    <option value="2">Employee</option>
                    <option value="3">Spouse</option>
                    <option value="4">Child</option>
                    <option value="5">Father</option>
                    <option value="6">Mother</option>
                    <option value="7">Sister</option>
                    <option value="8">Brother</option>
                    <option value="9">Domestic Worker</option>
                    <option value="10">Other</option>
                </SelectInput>
            </div>

            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <SelectInput name="memberIdType" label="Member Id Type" id="memberIdType"
                             get={member?.memberIdType}
                             set={v => updateMember('memberIdType', selected, v)} required={true}>
                    <option value=""></option>
                    <option value="1">Unified Number</option>
                    <option value="2">Emirates Id Number</option>
                    <option value="3">Visa File No</option>
                    <option value="4">Passport Number</option>
                    <option value="5">Birth Certificate Number</option>
                </SelectInput>
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <SelectInput name="memberType" label="Member Type" id="memberType" type="text"
                             get={member?.memberType}
                             set={v => updateMember('memberType', selected, v)} required={true}>
                    <option value=""></option>
                    <option value="1">Primary</option>
                    <option value="2">Dependent</option>
                </SelectInput>
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="sponsorIdNo" label="Sponsor Id No" id="sponsorIdNo"
                           get={member?.sponsorIdNo}
                           set={v => updateMember('sponsorIdNo', selected, v)} required={true}
                />
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <SelectInput name="sponsorIdType" label="Sponsor Id Type" id="sponsorIdType"
                             get={member?.sponsorIdType}
                             set={v => updateMember('sponsorIdType', selected, v)} required={true}>
                    <option value=""></option>
                    <option value="1">Unified Number</option>
                    <option value="2">Emirates Id Number</option>
                    <option value="3">Visa File No</option>
                    <option value="4">Passport No</option>
                </SelectInput>
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="membershipCardNo" label="Membership Card No" id="membershipCardNo" type="text"
                           get={member?.membershipCardNo}
                           set={v => updateMember('membershipCardNo', selected, v)} required={true}
                />
            </div>

            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="className" label="Class Name" id="className" type="text"
                           get={member?.className}
                           set={v => updateMember('className', selected, v)} required={true}
                />
            </div>

            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="salaryBand" label="Salary Band" id="salaryBand" type="text" get={member?.salaryBand}
                           set={v => updateMember('salaryBand', selected, v)} required={true}
                />
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="occupationDesc" label="Occupation Desc" id="occupationDesc" type="text"
                           get={member?.occupationDesc}
                           set={v => updateMember('occupationDesc', selected, v)} required={true}
                />
            </div>

            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <SelectInput name="emiratesOfVisaCode" label="Emirates Of Visa Code" id="emiratesOfVisaCode"
                             get={member?.emiratesOfVisaCode}
                             set={v => updateMember('emiratesOfVisaCode', selected, v)} required={true}>
                    <option value=""></option>
                    <option value="1">Abu Dhabi</option>
                    <option value="2">Dubai</option>
                    <option value="3">Sharjah</option>
                    <option value="4">Ajman</option>
                    <option value="5">Umm Al Quwain</option>
                    <option value="6">Ras Al Khaimah</option>
                    <option value="7">Fujairah</option>
                </SelectInput>
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <SelectInput name="emiratesOfLivingCode" label="Emirates Of Living Code" id="emiratesOfLivingCode"
                             get={member?.emiratesOfLivingCode}
                             set={v => updateMember('emiratesOfLivingCode', selected, v)} required={true}>
                    <option value=""></option>
                    <option value="1">Abu Dhabi</option>
                    <option value="2">Dubai</option>
                    <option value="3">Sharjah</option>
                    <option value="4">Ajman</option>
                    <option value="5">Umm Al Quwain</option>
                    <option value="6">Ras Al Khaimah</option>
                    <option value="7">Fujairah</option>
                </SelectInput>
            </div>
        </div>

    </div>
}

export default SlashdataPolicyCreateForm;