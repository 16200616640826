import React, {useMemo, useState} from 'react';
import DashboardLayout from "../../../layouts/DashboardLayout";
import PageHeader from "../../../components/PageHeader";
import {useQuery, useQueryClient} from "react-query";
import {useNavigate} from "react-router-dom";
import SelectInput from "../../../components/SelectInput";
import {apollo} from "../../../utils/apollo";
import {GET_INTEGRATION_LIST} from "../../../utils/graphql";
import usePolicyStore from "../../../store/policy";
import SlashdataPolicyCreateForm from "./forms/slashdata-policy-create-form";

const CURRENT_DAY = `0${new Date().getDate()}`.slice(-2);
const CURRENT_MONTH = `0${new Date().getMonth() + 1}`.slice(-2);
const CURRENT_YEAR = new Date().getFullYear().toString();

function PolicyCreate() {
    const [date, setDate] = useState({day: CURRENT_DAY, month: CURRENT_MONTH, year: CURRENT_YEAR});

    const queryClient = useQueryClient()
    const navigate = useNavigate();
    const {integrationUuid, setIntegrationUuid} = usePolicyStore();


    // const createPolicyMutation = useMutation({
    //     mutationFn: async () => {
    //         const {data} = await apollo.mutate({
    //             mutation: CREATE_POLICY, variables: {
    //                 input: {
    //                     useExistingCustomer, splitPolicy, customerUuid, name, email, phone, products,
    //                     address, city, zipCode, country, expiresAt,
    //                     saveCustomerDetails: !useExistingCustomer
    //                 }
    //             },
    //         });
    //         return data?.createPolicy;
    //     }, onSuccess: () => {
    //         queryClient.invalidateQueries(['policy_list']).then(() => {
    //             resetPolicyFormAction();
    //             setTimeout(() => navigate("/policies"), 2000);
    //         });
    //     },
    // })

    // const selectedDateFormated = useDeepMemo(() => {
    //     try {
    //         return new Date(`${date.year}-${date.month}-${date.day}`)
    //     } catch (e) {
    //         return null;
    //     }
    // }, [date]);

    const fetchIntegrationList = useQuery({
        queryKey: 'integration_list', queryFn: async () => {
            const {data} = await apollo.query({
                query: GET_INTEGRATION_LIST, variables: {},
            });
            return data?.getIntegrationList;
        }, keepPreviousData: false
    })

    const integrationList = useMemo(() => fetchIntegrationList?.isSuccess ? fetchIntegrationList?.data?.data : null, [fetchIntegrationList]);
    const integration = useMemo(() => {
        const l = integrationList?.filter(i => i.integrationUuid === integrationUuid);
        return l && l.length > 0 ? l[0] : null;
    }, [integrationUuid, integrationList]);

    return (<DashboardLayout>
        <PageHeader back="/policies" title="Create Policy" description="Create a new policy for your members.">
            <div className="shrink-0 w-full sm:w-[250px]">
                <SelectInput name="integration_list" label="Integration" id="integration_list" get={integrationUuid}
                             set={setIntegrationUuid}>
                    <option value=""></option>
                    {integrationList && integrationList?.map((integration) => (
                        <option key={integration?.integrationUuid}
                                value={integration?.integrationUuid}>{`${integration?.name} | ${integration?.integrationUuid}`}</option>))}
                </SelectInput>
            </div>
        </PageHeader>

        <div className="mt-6">
            {integration?.integrationType === 'slashdata' && <SlashdataPolicyCreateForm integration={integration}/>}
            {(!integrationUuid) && <div className="flex items-center justify-center h-40 text-gray-500 font-light text-xl">
                Select integration to continue.
            </div>}
        </div>

        {/*<div className="mt-6">*/}
        {/*    <div className="flex items-center justify-between gap-4">*/}
        {/*        <h3 className="text-lg text-blue-500 py-2">Products</h3>*/}
        {/*        /!*<Button type="button" onClick={addProduct} secondary={true}>*!/*/}
        {/*        /!*	<span className="flex items-center gap-2"><PlusIcon className="w-5 h-5"/> Add Product</span>*!/*/}
        {/*        /!*</Button>*!/*/}
        {/*    </div>*/}
        {/*    <div>*/}
        {/*        {products.map((product) => (*/}
        {/*            <ProductFormItem key={product?.id} product={product} updateProduct={updateProduct}*/}
        {/*                             deleteProduct={deleteProduct}/>))}*/}

        {/*        <div className="flex items-center justify-center mt-4">*/}
        {/*            <button type="button" onClick={addProduct}*/}
        {/*                    className="bg-gray-200 hover:bg-gray-200/80 border border-b-4 border-gray-300/60 hover:border-gray-300/40 px-4 py-2 rounded-full">*/}
        {/*                <span className="flex items-center gap-2"><PlusIcon className="w-5 h-5"/> Add Product</span>*/}
        {/*            </button>*/}
        {/*        </div>*/}


        {/*        <div className="mt-4 flex items-center gap-6 flex-wrap">*/}
        {/*            /!*<Toggle get={'} set={()=>{}} => setSplitPolicy(value)} Split Policy</Toggle>*!/*/}
        {/*            /!*<Toggle get={'} set={()=>{}} => setSaveCustomerDetails(value)}>Save customer details</Toggle>*!/*/}
        {/*        </div>*/}


        {/*        <div className="mt-6 flex flex-wrap items-center gap-4">*/}
        {/*            <Button type="button" onClick={() => createPolicyMutation.mutate()}>*/}
        {/*                <span className="flex items-center gap-2">Generate Policy Link</span>*/}
        {/*            </Button>*/}
        {/*            <Button type="button" onClick={() => resetPolicyFormAction()} secondary={true}>*/}
        {/*                <span className="flex items-center gap-2">Reset Form</span>*/}
        {/*            </Button>*/}
        {/*            <span className="text-lg">*/}
        {/*				Total amount: {Number(totalAmount.toFixed(2))}*/}
        {/*			</span>*/}
        {/*        </div>*/}

        {/*    </div>*/}
        {/*</div>*/}
    </DashboardLayout>);
}

export default PolicyCreate;
