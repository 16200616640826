import React from 'react';
import {clsx} from "clsx";

function ButtonLink({children, href, target, secondary, className}) {
	return (
		<a
			href={href}
			target={target || '_self'}
			className={clsx(
				className,
				secondary
					? 'from-gray-500 to-gray-600 hover:from-gray-400 hover:to-gray-500'
					: 'from-secondary-500 to-primary-600 hover:from-secondary-400 hover:to-primary-500',
				"block rounded-md bg-gradient-to-br px-3 py-2 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500")}
		>
			{children}
		</a>
	);
}

export default ButtonLink;