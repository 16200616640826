import React, {useEffect, useMemo, useState} from 'react';
import {useMutation, useQuery, useQueryClient} from "react-query";
import {apollo} from "../../../../utils/apollo";
import {useNavigate, useParams} from "react-router-dom";
import {CANCEL_SLASHDATA_MEMBER, GET_SLASHDATA_MEMBER} from "../../../../utils/graphql";
import Loading from "../../../../components/Loading";
import TextInput from "../../../../components/TextInput";
import useMemberEditStore from "../../../../store/member-edit";
import SelectInput from "../../../../components/SelectInput";
import Button from "../../../../components/Button";
import {toast} from "react-toastify";

function SlashdataMemberEditForm() {
    const [viewOnly, setViewOnly] = useState(false);
    const {member_uuid} = useParams();
    const queryClient = useQueryClient()
    const navigate = useNavigate();

    const {
        id,
        memberRefNo,
        enrolmentIssueDate,
        enrolmentStartDate,
        unifiedNo,
        emiratesIdNo,
        visaFileNo,
        birthCertNo,
        passportNo,
        gender,
        nationalityCode,
        dateOfBirth,
        fullNameEn,
        firstNameEn,
        middleNameEn,
        lastNameEn,
        fullNameAr,
        firstNameAr,
        middleNameAr,
        lastNameAr,
        maritalStatus,
        relationWithSponsor,
        memberIdType,
        memberType,
        sponsorIdNo,
        sponsorIdType,
        membershipCardNo,
        salaryBand,
        occupationDesc,
        emiratesOfVisaCode,
        emiratesOfLivingCode,
        memberCreationRefNo,
        cancellationDate,
        integrationStatus,
        status,
        createdAt,
        updatedAt,
        setMemberFromGraphQLRequest,
        updateMember,
    } = useMemberEditStore();

    const fetchSlashdataMember = useQuery({
        queryKey: ['slashdata_member', {memberUuid: member_uuid}], queryFn: async () => {
            const {data} = await apollo.query({
                query: GET_SLASHDATA_MEMBER, variables: {
                    memberUuid: member_uuid,
                },
            });
            return data?.getSlashdataMember;
        },
        keepPreviousData: false,
        onSuccess: (data) => {
            setMemberFromGraphQLRequest(data);
            if (data.integrationStatus === 'canceled') {
                setViewOnly(true)
            }
        }
    });

    // const member = useMemo(() => fetchSlashdataMember?.isSuccess ? fetchSlashdataMember?.data : null, [fetchSlashdataMember]);

    const cancelMemberMutation = useMutation({
        mutationFn: async () => {
            const {data} = await apollo.mutate({
                mutation: CANCEL_SLASHDATA_MEMBER,
                variables: {
                    slashdataMemberUuid: member_uuid
                },
            });
            return data?.cancelSlashdataMember;
        },
        onError: ((error) => {
            // toast.error("Recheck operation failed")
            console.error(error)
        }),
        onSuccess: async (data) => {
            if (data) {
                toast.success("Member has been canceled")
                queryClient.invalidateQueries(['slashdata_member'])
                setViewOnly(true)
                // setTimeout(() => {
                //     queryClient.invalidateQueries(['slashdata_policy_member_list']).then(() => {
                //         setTimeout(() => navigate(`/policy/view/${}`), 2000);
                //     });
                // }, 1000)
            } else {
                toast.error("Failed to cancel the member")
            }
        },
        // onSettled: () => {
        //     queryClient.invalidateQueries(['policy_list']);
        // }
    })

    if (fetchSlashdataMember?.isLoading) return <Loading/>
    if (!memberRefNo) return <div className="flex items-center justify-center h-40 text-gray-500 font-light text-xl">
        Member not found!
    </div>;

    return (
        <div className="grid grid-cols-12 gap-3">
            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="memberRefNo" label="Member Ref No" id="memberRefNo" type="text"
                           get={memberRefNo}
                           set={v => updateMember('memberRefNo', v)} required={true}
                />
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="enrolmentIssueDate" label="Enrolment Issue Date" id="enrolmentIssueDate"
                           type="datetime-local" get={enrolmentIssueDate}
                           set={v => updateMember('enrolmentIssueDate', v)}
                           format="yyyy-MM-dd"
                           required={true}/>
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="enrolmentStartDate" label="Enrolment Start Date" id="enrolmentStartDate"
                           type="datetime-local" get={enrolmentStartDate}
                           set={v => updateMember('enrolmentStartDate', v)}
                           format="yyyy-MM-dd"
                           required={true}/>
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="unifiedNo" label="Unified No" id="unifiedNo" type="text" get={unifiedNo}
                           set={v => updateMember('unifiedNo', v)} required={true}
                />
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="emiratesIdNo" label="Emirates Id No" id="emiratesIdNo" type="text"
                           get={emiratesIdNo}
                           set={v => updateMember('emiratesIdNo', v)} required={true}
                />
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="visaFileNo" label="Visa File No" id="visaFileNo" type="text" get={visaFileNo}
                           set={v => updateMember('visaFileNo', v)} required={true}
                />
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="birthCertNo" label="Birth Cert No" id="birthCertNo" type="text"
                           get={birthCertNo}
                           set={v => updateMember('birthCertNo', v)} required={true}
                />
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="passportNo" label="Passport No" id="passportNo" type="text" get={passportNo}
                           set={v => updateMember('passportNo', v)} required={true}
                />
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <SelectInput name="gender" label="Gender" id="gender"
                             get={gender}
                             set={v => updateMember('gender', v)} required={true}>
                    <option value="0">Female</option>
                    <option value="1">Male</option>
                </SelectInput>
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="nationalityCode" label="Nationality Code" id="nationalityCode" type="text"
                           get={nationalityCode}
                           set={v => updateMember('nationalityCode', v)} required={true}
                />
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="dateOfBirth" label="Date Of Birth" id="dateOfBirth"
                           type="datetime-local" get={dateOfBirth}
                           set={v => updateMember('dateOfBirth', v)}
                           format="yyyy-MM-dd"
                           required={true}/>
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="fullNameEn" label="Full Name En" id="fullNameEn" type="text" get={fullNameEn}
                           set={v => updateMember('fullNameEn', v)} required={true}
                />
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="firstNameEn" label="First Name En" id="firstNameEn" type="text"
                           get={firstNameEn}
                           set={v => updateMember('firstNameEn', v)} required={true}
                />
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="middleNameEn" label="Middle Name En" id="middleNameEn" type="text"
                           get={middleNameEn}
                           set={v => updateMember('middleNameEn', v)} required={true}
                />
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="lastNameEn" label="Last Name En" id="lastNameEn" type="text" get={lastNameEn}
                           set={v => updateMember('lastNameEn', v)} required={true}
                />
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="fullNameAr" label="Full Name Ar" id="fullNameAr" type="text" get={fullNameAr}
                           set={v => updateMember('fullNameAr', v)} required={true}
                />
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="firstNameAr" label="First Name Ar" id="firstNameAr" type="text"
                           get={firstNameAr}
                           set={v => updateMember('firstNameAr', v)} required={true}
                />
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="middleNameAr" label="Middle Name Ar" id="middleNameAr" type="text"
                           get={middleNameAr}
                           set={v => updateMember('middleNameAr', v)} required={true}
                />
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="lastNameAr" label="Last Name Ar" id="lastNameAr" type="text" get={lastNameAr}
                           set={v => updateMember('lastNameAr', v)} required={true}
                />
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <SelectInput name="maritalStatus" label="Marital Status" id="maritalStatus"
                             get={maritalStatus}
                             set={v => updateMember('maritalStatus', v)} required={true}>
                    <option value="1">Married</option>
                    <option value="2">Single</option>
                </SelectInput>
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <SelectInput name="relationWithSponsor" label="Relation With Sponsor" id="relationWithSponsor"
                             get={relationWithSponsor}
                             set={v => updateMember('relationWithSponsor', v)} required={true}>
                    <option value="1">Applicant</option>
                    <option value="2">Employee</option>
                    <option value="3">Spouse</option>
                    <option value="4">Child</option>
                    <option value="5">Father</option>
                    <option value="6">Mother</option>
                    <option value="7">Sister</option>
                    <option value="8">Brother</option>
                    <option value="9">Domestic Worker</option>
                    <option value="10">Other</option>
                </SelectInput>
            </div>

            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <SelectInput name="memberIdType" label="Member Id Type" id="memberIdType"
                             get={memberIdType}
                             set={v => updateMember('memberIdType', v)} required={true}>
                    <option value="1">Unified Number</option>
                    <option value="2">Emirates Id Number</option>
                    <option value="3">Visa File No</option>
                    <option value="4">Passport Number</option>
                    <option value="5">Birth Certificate Number</option>
                </SelectInput>
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <SelectInput name="memberType" label="Member Type" id="memberType" type="text"
                             get={memberType}
                             set={v => updateMember('memberType', v)} required={true}>
                    <option value="1">Primary</option>
                    <option value="2">Dependent</option>
                </SelectInput>
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="sponsorIdNo" label="Sponsor Id No" id="sponsorIdNo"
                           get={sponsorIdNo}
                           set={v => updateMember('sponsorIdNo', v)} required={true}
                />
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <SelectInput name="sponsorIdType" label="Sponsor Id Type" id="sponsorIdType"
                             get={sponsorIdType}
                             set={v => updateMember('sponsorIdType', v)} required={true}>
                    <option value="1">Unified Number</option>
                    <option value="2">Emirates Id Number</option>
                    <option value="3">Visa File No</option>
                    <option value="4">Passport No</option>
                </SelectInput>
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="membershipCardNo" label="Membership Card No" id="membershipCardNo" type="text"
                           get={membershipCardNo}
                           set={v => updateMember('membershipCardNo', v)} required={true}
                />
            </div>

            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="salaryBand" label="Salary Band" id="salaryBand" type="text" get={salaryBand}
                           set={v => updateMember('salaryBand', v)} required={true}
                />
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <TextInput name="occupationDesc" label="Occupation Desc" id="occupationDesc" type="text"
                           get={occupationDesc}
                           set={v => updateMember('occupationDesc', v)} required={true}
                />
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <SelectInput name="emiratesOfVisaCode" label="Emirates Of Visa Code" id="emiratesOfVisaCode"
                             get={emiratesOfVisaCode}
                             set={v => updateMember('emiratesOfVisaCode', v)} required={true}>
                    <option value="1">Abu Dhabi</option>
                    <option value="2">Dubai</option>
                    <option value="3">Sharjah</option>
                    <option value="4">Ajman</option>
                    <option value="5">Umm Al Quwain</option>
                    <option value="6">Ras Al Khaimah</option>
                    <option value="7">Fujairah</option>
                </SelectInput>
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3">
                <SelectInput name="emiratesOfLivingCode" label="Emirates Of Living Code" id="emiratesOfLivingCode"
                             get={emiratesOfLivingCode}
                             set={v => updateMember('emiratesOfLivingCode', v)} required={true}>
                    <option value="1">Abu Dhabi</option>
                    <option value="2">Dubai</option>
                    <option value="3">Sharjah</option>
                    <option value="4">Ajman</option>
                    <option value="5">Umm Al Quwain</option>
                    <option value="6">Ras Al Khaimah</option>
                    <option value="7">Fujairah</option>
                </SelectInput>
            </div>

            <div className="col-span-12 flex gap-3">
                {['completed', 'pending', 'failed'].includes(integrationStatus) &&
                    <Button onClick={() => toast.info('This action is disabled temporary')}>Update Member</Button>
                }

                {['completed'].includes(integrationStatus) &&
                    <Button onClick={() => cancelMemberMutation.mutate()}
                            className="from-red-500 to-red-600 hover:from-red-400 hover:to-red-500">Cancel
                        Member</Button>
                }
            </div>
        </div>
    );
}

export default SlashdataMemberEditForm;