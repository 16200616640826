export const POLICY_LIST_STATUS_FOR_FILTER = [
    {name: 'All', column: '', count: 0},
    {name: 'Draft', column: 'draft', count: 0},
    {name: 'Pending', column: 'pending', count: 0},
    {name: 'Failed', column: 'failed', count: 0},
    {name: 'Canceled', column: 'canceled', count: 0},
    {name: 'Completed', column: 'completed', count: 0},
];


export const MEMBER_LIST_STATUS_FOR_FILTER = [
    {name: 'All', column: '', count: 0},
    {name: 'Draft', column: 'draft', count: 0},
    {name: 'Pending', column: 'pending', count: 0},
    {name: 'Failed', column: 'failed', count: 0},
    {name: 'Canceled', column: 'canceled', count: 0},
    {name: 'Completed', column: 'completed', count: 0},
];

export const FILE_IMPORT_STATUS = [
    {name: 'Overview', column: 'overview', count: 0},
    {name: 'Under Process Files', column: 'processing', count: 0},
    {name: 'Processed Files', column: 'completed', count: 0},
    {name: 'Rejected Files', column: 'failed', count: 0},
];