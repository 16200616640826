import {create} from 'zustand'
import {persist} from "zustand/middleware";
// import {createJSONStorage} from "zustand/middleware";

const reportClass = () => {
    return {
        memberAdditionDateStart: null,
        memberAdditionDateEnd: null,

        policyNumber: null,
        policyIssueDate: null,
        policyStartDate: null,
        policyExpiryDate: null,
        policyType: null,
        policyOwnerType: null,
        policyOwnerName: null,
        policyOwnerId: null,
        planName: null,

        memberRefNo: null,
        enrolmentIssueDate: null,
        enrolmentStartDate: null,
        unifiedNo: null,
        emiratesIdNo: null,
        visaFileNo: null,
        birthCertNo: null,
        passportNo: null,
        gender: null,
        nationalityCode: null,
        dateOfBirth: null,
        fullNameEn: null,
        firstNameEn: null,
        middleNameEn: null,
        lastNameEn: null,
        fullNameAr: null,
        firstNameAr: null,
        middleNameAr: null,
        lastNameAr: null,
        maritalStatus: null,
        relationWithSponsor: null,
        memberIdType: null,
        memberType: null,
        sponsorIdNo: null,
        sponsorIdType: null,
        membershipCardNo: null,
        salaryBand: null,
        occupationDesc: null,
        emiratesOfVisaCode: null,
        emiratesOfLivingCode: null,
    };
}

const useReportStore = create((set, get) => ({
    ...reportClass(),

    updateMember: (key, value) => set((state) => {
        let stateCopy = state;
        stateCopy[key] = value
        return {...state, stateCopy};
    }),
    resetReportForm: () => set({...reportClass()}),

    // setMemberFromGraphQLRequest: (values) => set({
    //     id: null,
    //     memberRefNo: null,
    //     ...values
    // }),

    // setPolicyNumber: (value) => set({policyNumber: value}),
    // setPolicyIssueDate: (value) => set({policyIssueDate: value}),
    // setPolicyStartDate: (value) => set({policyStartDate: value}),
    // setPolicyExpiryDate: (value) => set({policyExpiryDate: value}),
    // setPolicyType: (value) => set({policyType: value}),
    // setPolicyOwnerType: (value) => set({policyOwnerType: value}),
    // setPolicyOwnerName: (value) => set({policyOwnerName: value}),
    // setPolicyOwnerId: (value) => set({policyOwnerId: value}),
    // setPlanName: (value) => set({planName: value}),

}))

export default useReportStore;